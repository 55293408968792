import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  useColorModeValue,
  Portal,
} from '@chakra-ui/react';
import { API_URL } from '../../services/api';
import { HamburgerIcon, ChevronDownIcon } from '@chakra-ui/icons';
import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { SimpleGrid } from '@chakra-ui/react';

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
} from '@chakra-ui/react';
import './css/NavigationBar.css';
import Logo from '../../assets/sarath_IMG/brakeup-logo-nav.png';
import { Image } from '@chakra-ui/react';
import { SignIn } from '../../views/Login/LoginSignup';

import {
  Stack,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import logo2 from '../../assets/sarath_IMG/LOGO1.svg';
import cart from '../../assets/sarath_IMG/cart.svg';
import { VscAccount } from 'react-icons/vsc';

import { IoBookmarkOutline } from 'react-icons/io5';
import { IoLocationOutline } from 'react-icons/io5';
import { MdOutlineNotificationsActive } from 'react-icons/md';
import { FiGift } from 'react-icons/fi';
import { VscHistory } from 'react-icons/vsc';
import { GiHomeGarage } from 'react-icons/gi';
import { FiLogOut } from 'react-icons/fi';

import { useHistory } from 'react-router-dom';

import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
} from '@chakra-ui/react';

import { IoMenu } from 'react-icons/io5';
import moblogo from '../../assets/sarath_IMG/Group 31775.svg';
import mobcart from '../../assets/sarath_IMG/Group 31232.svg';
import { BsCart3 } from 'react-icons/bs';
import { BiChevronDown } from 'react-icons/bi';

import { get, post } from '../../services/api';
import CustomSelect from '../../views/FilterPage/CustomSelect';
import { useSelector, useDispatch } from 'react-redux';
import {
  setUserName,
  setUserMobile,
  setUserEmail,
  setToken,
  setAccess,
  setUser,
} from '../../stateManagement/user/userSlice';
import { setCartCount } from '../../stateManagement/user/cartCountSlice';
import { useQuery } from '../../utilities';
import { setCategoryFilter } from '../../stateManagement/user/categoryFilterSlice';
import { Mobilesearch } from '../../views/HomePage/Mobilesearch';

export const NavigationBar = (props) => {
  const userName = useSelector((state) => state.user.name);
  const userMobile = useSelector((state) => state.user.mobile);
  const cartCount = useSelector((state) => state.count.number);
  const updateCart = useSelector((state) => state.count.updateCart);
  const token = useSelector((state) => state.user.token);

  const user = useSelector((state) => state.user);
  const access = useSelector((state) => state.user.access);

  // console.log(user, "newTokennewToken");

  const dispatch = useDispatch();

  const [isSignIn, setIsSignIn] = useState(false);
  const closeSignIn = () => {
    setIsSignIn(!isSignIn);
  };

  // const [isloggedin, setisloggedin] = useState(false);

  let history = useHistory();

  let isloggedin = false;
  // let username
  // let usermobile
  // let useremail
  // const tokenType = token?.data?.credAuthResult
  // const tokenType = JSON.parse(localStorage.getItem("token")).data?.credAuthResult

  // console.log(tokenType, "tokenTypetokenType");

  if (access) {
    // username = userName
    // usermobile = userMobile
    isloggedin = true;
  } else {
    isloggedin = false;
  }

  useEffect(() => {
    // console.log("accessss");
    // if (access) {
    //     // console.log("accessssrhhh");

    //     isloggedin = true
    // } else {
    //     dispatch(setUserName())
    //     dispatch(setUserMobile())
    //     isloggedin = false
    // }

    // console.log("accessss render newtoken");

    const token2 = JSON.parse(localStorage.getItem('token'));

    if (token2 && token2.data?.credAuthResult.type == 'Access') {
      // console.log(token2.data?.credAuthResult?.mobile, "accessed");
      dispatch(setUserName(token2.data?.credAuthResult?.name));
      dispatch(setUserMobile(token2.data?.credAuthResult?.mobile));
      dispatch(setUserEmail(token2.data?.credAuthResult?.email));
      dispatch(setToken(token2));
      // // dispatch(setUser())
      dispatch(setAccess(true));
      isloggedin = true;
    } else {
      // console.log(token2 && token2.data?.credAuthResult.type, "notaccessed");
      dispatch(setUserName(''));
      dispatch(setUserMobile(''));
      dispatch(setUserEmail(''));
      dispatch(setToken(token2));
      // // dispatch(setUser())
      dispatch(setAccess(false));
      isloggedin = false;
    }
  }, [access]);

  // console.log(token, username, usermobile, "tokennn");

  const refreshPage = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('pincode');
    window.localStorage.removeItem('appliedpromocode');
    dispatch(setAccess(false));
    history.push('/');
    history.replace();
    window.location.reload();
  };

  const btnRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  //Search Alert Dialog PopUp
  const {
    isOpen: isOpenSearch,
    onOpen: onOpenSearch,
    onClose: onCloseSearch,
  } = useDisclosure();

  let BottomNAvBarExemption = ['/cart', '/buynow/'];

  // cart item number on cart

  const [itemNo, setitemNo] = useState(0);

  useEffect(() => {
    // console.log(updateCart, "updateCartupdateCart");
    get('/api/v2/products/updatecart/')
      .then((res) => {
        // setitemNo(res?.count);
        dispatch(setCartCount(res?.count));

        // console.log(res, "cartnumberr");
      })
      .catch((err) => {
        dispatch(setCartCount(0));
      });
  }, [updateCart]);

  const MobileNavbar = () => {
    return (
      <>
        <Flex
          bgColor="#F5F5F5"
          justifyContent="space-between"
          alignItems="center"
          p="15px"
          boxShadow="base"
          width={'100%'}
          display={{ base: 'flex', md: 'none' }}
        >
          <Box h="5vh" w="5vh" onClick={onOpen}>
            <lottie-player
              src={'https://api.brakeup.in/api/v1/json/Mobile_Menu.json'}
              background="transparent"
              speed="1"
              autoplay
            ></lottie-player>
          </Box>

          <Image
            width="30vw"
            minWidth="140px"
            maxWidth="180px"
            h="5vh"
            src={moblogo}
            onClick={() => history.push('/')}
          />

          {cartCount == 0 ? (
            <Box
              h="5vh"
              w="5vh"
              position="relative"
              onClick={() => history.push('/cart')}
            >
              <lottie-player
                src={'https://api.brakeup.in' + '/api/v1/json/Mobile_Cart.json'}
                background="transparent"
                speed="1"
                autoplay
              ></lottie-player>
            </Box>
          ) : (
            <Box
              h="5vh"
              w="5vh"
              position="relative"
              onClick={() => history.push('/cart')}
            >
              <lottie-player
                src={'https://api.brakeup.in/api/v1/json/Mobile_Cart.json'}
                background="transparent"
                speed="1"
                loop
                autoplay
              ></lottie-player>
              <Flex
                position="absolute"
                top="-5px"
                justifyContent="center"
                width="100%"
              >
                <Text
                  position="relative"
                  ml="8px"
                  textAlign="center"
                  fontSize="12px"
                >
                  {cartCount}{' '}
                </Text>
                <Box
                  position="relative"
                  ml="3px"
                  top=""
                  height="5px"
                  width="5px"
                  borderRadius="50%"
                  bgColor="#f9e107"
                  boxShadow="base"
                ></Box>
              </Flex>
            </Box>
          )}
        </Flex>

        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          finalFocusRef={btnRef}
          maxWidth="18rem"
        >
          <DrawerOverlay />
          <DrawerContent maxWidth="18rem" borderBottomRightRadius={'16px'}>
            {/* <DrawerCloseButton _focus={{ border: 'none' }} style={{ border: 'none', fontSize: '18px' }} /> */}
            <DrawerBody pl={0} pr={0} pt={10} pb={10} height="fit-content">
              <Flex w="100%" flexDirection="column" alignItems="flex-start">
                {isloggedin ? (
                  <Box w="100%">
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      _focus={{ bgColor: 'none' }}
                    >
                      {/* <VscAccount style={{ fontSize: '25px', marginLeft: "10px" }} /> */}
                      <Text
                        maxWidth="250px"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        ml="15px"
                        className="font_Manrope700"
                      >
                        {' '}
                        Hi{' '}
                        <span style={{ marginLeft: '10px' }}>
                          {' '}
                          {userName || userMobile}{' '}
                        </span>{' '}
                      </Text>
                    </Flex>
                    <Flex
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      _focus={{ bgColor: '#ECE9D2' }}
                      onClick={() => {
                        onClose();
                        history.push('/');
                      }}
                    >
                      <Text ml="30px" className="font_Manrope700">
                        Home
                      </Text>
                    </Flex>
                    <Flex
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      _focus={{ bgColor: '#ECE9D2' }}
                      onClick={() => {
                        onClose();
                        onOpenSearch();
                      }}
                    >
                      <Text ml="30px" className="font_Manrope700">
                        Search
                      </Text>
                    </Flex>
                    <Flex
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      _focus={{ bgColor: '#ECE9D2' }}
                      onClick={() => {
                        onClose();
                        history.push('/myprofilemobile/6');
                      }}
                    >
                      {/* <VscAccount style={{ fontSize: '25px', marginLeft: "10px" }} /> */}
                      <Text ml="30px" className="font_Manrope700">
                        Profile
                      </Text>
                    </Flex>
                    <Flex
                      _disabled={!isloggedin}
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      _focus={{ bgColor: '#ECE9D2' }}
                      onClick={() => {
                        onClose();
                        history.push('/myprofilemobile/4');
                      }}
                    >
                      {/* <VscHistory style={{ fontSize: '25px', marginLeft: "10px" }} /> */}
                      <Text ml="30px" className="font_Manrope700">
                        Order History{' '}
                      </Text>
                    </Flex>
                    <Flex
                      _disabled={!isloggedin}
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      _focus={{ bgColor: '#ECE9D2' }}
                      onClick={() => {
                        onClose();
                        history.push('/myprofilemobile/0');
                      }}
                    >
                      {/* <IoBookmarkOutline style={{ fontSize: '25px', marginLeft: "10px" }} /> */}
                      <Text ml="30px" className="font_Manrope700">
                        {' '}
                        Saved List
                      </Text>
                    </Flex>
                    <Flex
                      _disabled={!isloggedin}
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      _focus={{ bgColor: '#ECE9D2' }}
                      onClick={() => {
                        onClose();
                        history.push('/myprofilemobile/1');
                      }}
                    >
                      {/* <IoLocationOutline style={{ fontSize: '25px', marginLeft: "10px" }} /> */}
                      <Text ml="30px" className="font_Manrope700">
                        Address Book{' '}
                      </Text>
                    </Flex>
                    <Flex
                      _disabled={!isloggedin}
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      _focus={{ bgColor: '#ECE9D2' }}
                      onClick={() => {
                        onClose();
                        history.push('/myprofilemobile/2');
                      }}
                    >
                      {/* <MdOutlineNotificationsActive style={{ fontSize: '25px', marginLeft: "10px" }} /> */}
                      <Text ml="30px" className="font_Manrope700">
                        {' '}
                        Notifications
                      </Text>
                    </Flex>
                    {/* <Flex _disabled={!isloggedin} alignItems='center' padding="10px" fontSize='18px' width="100%" _focus={{ bgColor: '#ECE9D2' }} onClick={() => { onClose(); history.push("/myprofilemobile/3") }} >
                                        <FiGift style={{ fontSize: '25px', marginLeft: "10px" }} />
                                        <Text ml="20px" className='font_Manrope'>Gift &amp; Coupons </Text>
                                    </Flex>
                                    <Flex _disabled={!isloggedin} alignItems='center' padding="10px" fontSize='18px' width="100%" _focus={{ bgColor: '#ECE9D2' }} onClick={() => { onClose(); history.push("/myprofilemobile/5") }} >
                                        <GiHomeGarage style={{ fontSize: '25px', marginLeft: "10px" }} />
                                        <Text ml="20px" className='font_Manrope'> My Garage</Text>
                                    </Flex> */}
                    <Flex
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      _focus={{ bgColor: '#ECE9D2' }}
                      onClick={() => {
                        onClose();
                        history.push('/customercare');
                      }}
                    >
                      <Text ml="30px" className="font_Manrope700">
                        {' '}
                        Customer Care
                      </Text>
                    </Flex>
                    <Flex
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      _focus={{ bgColor: '#ECE9D2' }}
                      onClick={() => {
                        onClose();
                        refreshPage();
                      }}
                    >
                      {/* <FiLogOut style={{ fontSize: '25px', marginLeft: "10px" }} /> */}
                      <Text ml="30px" className="font_Manrope700">
                        {' '}
                        Log out
                      </Text>
                    </Flex>
                  </Box>
                ) : (
                  <Box w="100%">
                    <Flex
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      _focus={{ bgColor: '#ECE9D2' }}
                      onClick={() => {
                        onClose();
                        history.push('/loginsignup');
                      }}
                    >
                      {/* <VscAccount style={{ fontSize: '25px', marginLeft: "10px" }} /> */}
                      <Text ml="30px" className="font_Manrope700">
                        Sign In
                      </Text>
                    </Flex>
                    <Flex
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      _focus={{ bgColor: '#ECE9D2' }}
                      onClick={() => {
                        onClose();
                        history.push('/');
                      }}
                    >
                      <Text ml="30px" className="font_Manrope700">
                        Home
                      </Text>
                    </Flex>
                    <Flex
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      _focus={{ bgColor: '#ECE9D2' }}
                      onClick={() => {
                        onClose();
                        onOpenSearch();
                      }}
                    >
                      <Text ml="30px" className="font_Manrope700">
                        Search
                      </Text>
                    </Flex>
                    <Flex
                      _disabled={!isloggedin}
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      color="#cdcdcd"
                      _focus={{ bgColor: '#ECE9D2' }}
                    >
                      {/* <VscHistory style={{ fontSize: '25px', marginLeft: "10px" }} /> */}
                      <Text ml="30px" className="font_Manrope400">
                        Order History{' '}
                      </Text>
                    </Flex>
                    <Flex
                      _disabled={!isloggedin}
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      color="#cdcdcd"
                      _focus={{ bgColor: '#ECE9D2' }}
                    >
                      {/* <IoBookmarkOutline style={{ fontSize: '25px', marginLeft: "10px" }} /> */}
                      <Text ml="30px" className="font_Manrope400">
                        {' '}
                        Saved List
                      </Text>
                    </Flex>
                    <Flex
                      _disabled={!isloggedin}
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      color="#cdcdcd"
                      _focus={{ bgColor: '#ECE9D2' }}
                    >
                      {/* <IoLocationOutline style={{ fontSize: '25px', marginLeft: "10px" }} /> */}
                      <Text ml="30px" className="font_Manrope400">
                        Address Book{' '}
                      </Text>
                    </Flex>
                    <Flex
                      _disabled={!isloggedin}
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      color="#cdcdcd"
                      _focus={{ bgColor: '#ECE9D2' }}
                    >
                      {/* <MdOutlineNotificationsActive style={{ fontSize: '25px', marginLeft: "10px" }} /> */}
                      <Text ml="30px" className="font_Manrope400">
                        {' '}
                        Notifications
                      </Text>
                    </Flex>
                    {/* <Flex _disabled={!isloggedin} alignItems='center' padding="10px" fontSize='18px' width="100%" color='#cdcdcd' _focus={{ bgColor: '#ECE9D2' }}  >
                                        <FiGift style={{ fontSize: '25px', marginLeft: "10px" }} />
                                        <Text ml="20px" className='font_Manrope'>Gift &amp; Coupons </Text>
                                    </Flex>
                                    <Flex _disabled={!isloggedin} alignItems='center' padding="10px" fontSize='18px' width="100%" color='#cdcdcd' _focus={{ bgColor: '#ECE9D2' }}  >
                                        <GiHomeGarage style={{ fontSize: '25px', marginLeft: "10px" }} />
                                        <Text ml="20px" className='font_Manrope'> My Garage</Text>
                                    </Flex> */}
                    <Flex
                      alignItems="center"
                      padding="15px"
                      fontSize="18px"
                      width="100%"
                      _focus={{ bgColor: '#ECE9D2' }}
                      onClick={() => {
                        onClose();
                        history.push('/customercare');
                      }}
                    >
                      <Text ml="30px" className="font_Manrope700">
                        Customer Care
                      </Text>
                    </Flex>
                  </Box>
                )}
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        {/* global search */}

        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={btnRef}
          onClose={onCloseSearch}
          isOpen={isOpenSearch}
        >
          <AlertDialogOverlay style={{ backdropFilter: 'blur(3px)' }} />

          <AlertDialogContent
            m="30px 0px"
            bgColor="transparent"
            boxShadow="none"
          >
            <AlertDialogBody bgColor="none" position="fixed" width="100%">
              <Mobilesearch onClose={onCloseSearch} />
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialog>
      </>
    );
  };

  return (
    <>
      <Box width={'100vw'}>
        {location.pathname == '/loginsignup' ? <></> : <MobileNavbar />}

        <DesktopNav
          closeSignIn={closeSignIn}
          userName={userName}
          userMobile={userMobile}
          cartCount={cartCount}
          isloggedin={isloggedin}
          refreshPage={refreshPage}
          BottomNAvBarExemption={BottomNAvBarExemption}
        />

        {BottomNAvBarExemption.some((el) => location.pathname.includes(el)) ? (
          ''
        ) : (
          <DesktopBottomNav />
        )}
      </Box>
      <SignIn isOpen={isSignIn} onClose={closeSignIn} />
    </>
  );
};

const DesktopBottomNav = () => {
  const dispatch = useDispatch();

  let history = useHistory();

  let query = useQuery();

  const filterCategory = useSelector((state) => state.categoryFilter.filter);

  const [categoryFilter, setcategoryFilter] = useState('');

  useEffect(() => {
    // if (location.pathname.includes("filter")) {
    //     setcategoryFilter(filterCategory.value)
    // }

    const categoryQUERY = query.get('category');
    if (location.pathname.includes('filter')) {
      setcategoryFilter(filterCategory.value);
    }
    // console.log(filterCategory.value, "navfilterCategory");
  }, [Object.keys(filterCategory).length >= 1]);

  const onClickCategory = (category) => {
    if (location.pathname.includes('filter')) {
      // setTimeout(() => {
      // getQueries()
      // }, 500);
      // history.push(`/filter?category=${value}`);
      // window.location.reload();

      dispatch(setCategoryFilter(category));
    } else {
      // history.push(`/filter`);
      dispatch(setCategoryFilter({}));
      history.push(`/filter?category=${category.value}`);
    }
    setcategoryFilter(category.value);
  };

  return (
    <Box
      zIndex={10}
      w="100%"
      bg="#EBE8D1"
      alignItems="center"
      className="navbar_titles"
      boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.2)"
    >
      <Flex
        className="desktop_only bottomNav"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Text
          className="font_Manrope titles"
          minWidth="14vw"
          p=".6rem"
          cursor="pointer"
          style={
            categoryFilter == 'Brake Pad'
              ? {
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4)',
                  borderRadius: '4px',
                }
              : {}
          }
          onClick={() =>
            onClickCategory({
              value: 'Brake Pad',
              name: 'category',
              label: 'Brake Pad',
            })
          }
          _hover={{
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4)',
            borderRadius: '4px',
          }}
        >
          BRAKE PADS
        </Text>
        <Text
          className="font_Manrope titles"
          minWidth="14vw"
          p=".6rem"
          cursor="pointer"
          style={
            categoryFilter == 'Brake Rotor'
              ? {
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4)',
                  borderRadius: '4px',
                }
              : {}
          }
          onClick={() =>
            onClickCategory({
              value: 'Brake Rotor',
              name: 'category',
              label: 'Brake Rotor',
            })
          }
          _hover={{
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4)',
            borderRadius: '4px',
          }}
        >
          BRAKE ROTORS
        </Text>
        <Text
          className="font_Manrope titles"
          minWidth="14vw"
          p=".6rem"
          cursor="pointer"
          style={
            categoryFilter == 'Brake Fluid'
              ? {
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4)',
                  borderRadius: '4px',
                }
              : {}
          }
          onClick={() =>
            onClickCategory({
              value: 'Brake Fluid',
              name: 'category',
              label: 'Brake Fluid',
            })
          }
          _hover={{
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4)',
            borderRadius: '4px',
          }}
        >
          BRAKE FLUIDS
        </Text>
        <Text
          className="font_Manrope titles"
          minWidth="14vw"
          p=".6rem"
          cursor="pointer"
          style={
            categoryFilter == 'Other'
              ? {
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4)',
                  borderRadius: '4px',
                }
              : {}
          }
          onClick={() =>
            onClickCategory({
              value: 'Other',
              name: 'category',
              label: 'Other',
            })
          }
          _hover={{
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4)',
            borderRadius: '4px',
          }}
        >
          OTHER PRODUCTS
        </Text>
        {/* <Text className='font_Manrope titles' minWidth='14vw' p='.6rem' cursor='pointer'

                    _hover={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4)', borderRadius: '4px' }}>
                    COMPARE PRODUCTS
                </Text> */}
      </Flex>
    </Box>
  );
};

const DesktopNav = (props) => {
  let history = useHistory();

  // function useComponentVisible(initialIsVisible) {
  //     const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  //     const ref = useRef(null);

  //     const handleClickOutside = (event) => {
  //         if (ref.current && !ref.current.contains(event.target)) {
  //             setIsComponentVisible(false);
  //         }
  //     };

  //     useEffect(() => {
  //         document.addEventListener('click', handleClickOutside, true);
  //         return () => {
  //             document.removeEventListener('click', handleClickOutside, true);
  //         };
  //     }, []);

  //     return { ref, isComponentVisible, setIsComponentVisible };
  // }

  const [searchdata, setSearchdata] = useState('');
  const [searchValue, setsearchValue] = useState('');
  // const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  // useEffect(() => {

  //     get('/api/v2/search/buyer_search/?q=Homepage_search').then(res => {

  //         setSearchdata(res.data)

  //     }).catch(err => {
  //     })

  //     return () => {
  //     }
  // }, [])

  const NavSearch = (e) => {
    get(`/api/v2/search/buyer_searches/?q=Homepage_Search&sq=${e}`)
      .then((res) => {
        // setSearchdata(res.data)
        FilteringSearch(res.data, e);
        // console.log(res.data, "NavSearch");
      })
      .catch((err) => {
        // setSearchdata([])
        // console.log(err, "NavSearch");
      });
  };

  const FilteringSearch = (filter, searchvalue) => {
    let url = '/filter';

    if (filter.make) {
      url = `${url}?make=${filter.make}&make_id=${filter.make_id}`;
    }
    if (filter.make && filter.model) {
      url = `${url}&model=${filter.model}&model_id=${filter.model_id}`;
    }
    if (filter.make && filter.model && filter.year) {
      url = `${url}&year=${filter.year}`;
    }
    // if (filter.make && filter.model && filter.year && filter.version) {
    //     url = `${url}&version=${filter.version}`
    // }
    if (
      filter.make &&
      filter.model &&
      filter.year &&
      filter.version &&
      filter.fuel_type
    ) {
      url = `${url}&fuel_type=${filter.fuel_type}`;
    }
    if (filter.axle) {
      let seperator =
        filter.make ||
        filter.model ||
        filter.year ||
        filter.version ||
        filter.fuel_type
          ? '&'
          : '?';
      url = `${url}${seperator}axle=${filter.axle}`;
    }
    if (filter.product_category) {
      let seperator =
        filter.make ||
        filter.model ||
        filter.year ||
        filter.version ||
        filter.fuel_type ||
        filter.axle
          ? '&'
          : '?';
      url = `${url}${seperator}category=${filter.product_category}`;
    }
    if (filter.brand) {
      let seperator =
        filter.make ||
        filter.model ||
        filter.year ||
        filter.version ||
        filter.fuel_type ||
        filter.axle ||
        filter.product_category
          ? '&'
          : '?';
      url = `${url}${seperator}brand=${filter.brand}`;
    }
    if (filter.seller_part_no) {
      localStorage.setItem(
        'seller_part_no',
        JSON.stringify(filter.seller_part_no)
      );
    }

    if (searchvalue != '') {
      if (location.pathname.includes('filter')) {
        history.push(url);
        window.location.reload();
        // console.log(filter,url);
      } else {
        // console.log(url);
        history.push(url);
      }
    }
  };
  // const handleKeyEnter = () => {
  //     isComponentVisible &&
  //     search2Filter(searchdata[select]);

  // }

  // const search2Filter = (data) => {
  //     console.log(data, "datadata");

  //     let url = "/filter"
  //     // let filterdata = {}

  //     if (data?.category) {

  //         url = `${url}?category=${data.category}`

  //         // filterdata["category"] = { "value": data?.category, "name": "category", "label": data?.category }
  //     }
  //     if (data?.brand) {
  //         url = `${url}&brand=${data.brand}`

  //         // filterdata["brand"] = { "value": data?.brand, "name": "brand", "label": data?.brand }
  //     }
  //     if (data?.axle) {
  //         url = `${url}&axle=${data.axle}`
  //     }

  //     if (location.pathname.includes("filter")) {
  //         history.push(url);
  //         window.location.reload();
  //     } else {
  //         history.push(url);
  //     }

  // }

  // const [select, setselect] = useState(0);

  //throttle function
  // const throttle = (func, limit=500) => {
  //     let inThrottle;
  //     return function() {
  //         const args = arguments;
  //         const context = this;
  //         if (!inThrottle) {
  //             func.apply(context, args);
  //             inThrottle = true;
  //             setTimeout(() => (inThrottle = false), limit);
  //         }
  //     }
  // }

  // const handleKey = (e) => {

  //     if (e.key === "ArrowDown") {
  //         if (select === searchlist.length - 1) return
  //         let elem = document.getElementById(`search_item_${select + 1}`)
  //         elem.scrollIntoView({ behavior: "smooth", block: "center" })
  //         setselect(select + 1)

  //     }
  //     if (e.key === "ArrowUp") {
  //         if (select === 0) return
  //         let elem = document.getElementById(`search_item_${select - 1}`)
  //         elem.scrollIntoView({ behavior: "smooth", block: "center" })
  //         setselect(select - 1)

  //     }
  // }

  // let searchlist = []
  // searchdata && searchdata.forEach((element, index) => {

  //     searchlist.push(
  //         <Flex id={`search_item_${index}`} p={2} pl={4} borderBottom='1px solid' borderColor='#eae6e6' alignItems='center'
  //             bgColor={index == select ? '#ECE9D2' : ''}
  //             onClick={() => { search2Filter(element) }}
  //             onMouseOver={() => setselect(index)}>
  //             <Image mr={2} ml={2} height={10} width={10} objectFit="contain" src={element.image[0]} />
  //             <Box p={1} textAlign='start'>
  //                 <Flex>
  //                     <Text mr={2} textTransform='uppercase' fontWeight='bold' fontSize="13px">{element.brand}</Text>
  //                     <Text textTransform='capitalize' fontWeight='bold' fontSize="13px">{element.prod_name}</Text>
  //                 </Flex>
  //                 <Text color='#959595' fontWeight='400' fontSize="10px">in {element.category}</Text>
  //             </Box>
  //         </Flex>
  //     )
  // })

  // searchdata.length == 0 ?

  //     searchlist.push(
  //         <Flex p={2} pl={4} borderBottom='1px solid' borderColor='#eae6e6' alignItems='center' justifyContent='center'>
  //             <Text mr={2} textTransform='capitalize' fontWeight='bold' fontSize="13px">No results found</Text>
  //         </Flex>
  //     )
  //     : ""

  return (
    <Flex
      className="topnav desktop_only"
      gap={2}
      width="100%"
      height="70px"
      justifyContent="space-between"
      alignItems="center"
      bgColor="#F9E107"
    >
      <Flex justifyContent="space-between" alignItems="center" w="58vw">
        <Flex>
          <Image
            h="2.6rem"
            src={Logo}
            className="brakeup-logo-with-border"
            cursor={'pointer'}
            onClick={() => history.push('/')}
          />
        </Flex>
        <Flex>
          <Stack spacing={4} width="45vw" pr="3vw" position="relative">
            <InputGroup className="font_Nunito ">
              <Input
                h="2rem"
                bgColor="#FFFFFF"
                className="navsearch"
                onChange={(e) => {
                  setsearchValue(e.target.value);
                  //     NavSearch(e.target.value),
                  //     setIsComponentVisible(true)
                }}
                // ref={ref}
                // onClick={() => setIsComponentVisible(true)}
                // onKeyDown={(e) => { handleKey(e) }}

                onKeyPress={(e) => {
                  e.key === 'Enter' && e.target.value != ''
                    ? NavSearch(searchValue)
                    : '';
                }}
                border="none"
                fontSize="14px"
                placeholder="Search for Products, Brands and More"
              />
              <InputRightElement
                className="navsearch"
                h="2rem"
                pl="4px"
                borderRightRadius="6px"
                // borderLeftRadius={0}
                bg="#ECE9D2"
                children={<SearchIcon pl="9px" p="2px" color="#85868D" />}
                //  ref={ref}
                // onClick={() => { handleKeyEnter() }}
                onClick={() => {
                  NavSearch(searchValue);
                  // setIsComponentVisible(true)
                }}
              />
            </InputGroup>

            {/* <Box position='absolute' top="20px" width='42vw' zIndex={5} >

                            <Box pt={0} mr='2.5rem' bgColor='white' boxShadow='0 4px 8px 0 rgba(0, 0, 0, 0.2)' borderRadius={5} d={isComponentVisible ? "block" : "none"} maxHeight="360px" overflowY='scroll' overflowX='-moz-hidden-unscrollable' tabIndex={0}>

                                {searchlist}

                            </Box>
                        </Box> */}
            <input
              style={{ display: 'none' }}
              id="openWebSignPopUp"
              onClick={() => props.closeSignIn()}
            />
          </Stack>
        </Flex>
      </Flex>
      <Flex
        className="SignCartMore"
        alignItems="center"
        justifyContent="space-between"
      >
        {props.isloggedin ? (
          <Flex alignItems="center">
            <Text
              className="nunitosans-semi-bold-black-pearl signcartmorefont"
              fontWeight="400"
              textOverflow="ellipsis"
              display="inline-block"
              whiteSpace="nowrap"
              overflow="hidden"
              maxWidth="110px"
            >
              Hi {props.userName || props.userMobile}
            </Text>
          </Flex>
        ) : (
          <Flex
            flexShrink="0"
            p={1}
            alignItems="center"
            cursor="pointer"
            className="signhover"
            onClick={() => props.closeSignIn()}
          >
            <VscAccount
              className="nunitosans-semi-bold-black-pearl signcartmorefont signiconfont"
              size="1.2rem"
            />
            <Text
              className="nunitosans-semi-bold-black-pearl signcartmorefont signfont"
              textOverflow="ellipsis"
              display="inline-block"
              whiteSpace="nowrap"
              pl=".4rem"
              width={'61px'}
              textAlign={'center'}
            >
              Sign in
            </Text>
          </Flex>
        )}

        <Spacer />
        <Flex
          flexShrink="0"
          p={1}
          alignItems="center"
          cursor="pointer"
          className="carthover"
          onClick={() => {
            props.BottomNAvBarExemption.some((el) =>
              location.pathname.includes(el)
            )
              ? window.location.reload()
              : history.push('/cart');
          }}
        >
          <Box>
            {props.cartCount !== 0 ? (
              <Box
                position="absolute"
                className="cartquantitypop cartfont"
                top="7px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginLeft="5px"
                height="24px"
                width="25px"
                borderRadius="50%"
                bgColor={'#EBE8D1'}
                fontSize="12px"
                boxShadow="base"
              >
                {props.cartCount}
              </Box>
            ) : (
              ''
            )}

            {/* <Image src={cart} /> */}
            <BsCart3 className="carticonfont" fontSize={'1.2rem'} />
          </Box>
          <Text
            className="nunitosans-semi-bold-black-pearl signcartmorefont cartfont"
            pl=".3rem"
            width={'50px'}
            textAlign={'center'}
            whiteSpace="nowrap"
          >
            Cart
          </Text>
        </Flex>
        <Spacer />
        <Flex flexShrink="0" p={1} alignItems="center">
          <Menu bg="#0000001A">
            <MenuButton
              className="nunitosans-semi-bold-black-pearl signcartmorefont morehover"
              p={0}
              bg="none"
              cursor="pointer"
              _active={{
                border: 'none',
                fontWeight: '800',
                strokeWidth: '0.5px',
              }}
              _focus={{ border: 'none', strokeWidth: '0.5px' }}
              _hover={{ fontWeight: '800' }}
              width={'70px'}
              textAlign={'center'}
              whiteSpace="nowrap"
              margin={0}
              as={Button}
              rightIcon={<BiChevronDown className="moreiconfont" />}
            >
              More
            </MenuButton>

            {props.isloggedin ? (
              <MenuList pt={0} pb={0} zIndex={2}>
                <MenuItem
                  _focus={{ bgColor: 'none' }}
                  _hover={{ bgColor: '#ECE9D2' }}
                  onClick={() => history.push('/myprofile/0')}
                >
                  {' '}
                  Saved List
                </MenuItem>
                <MenuItem
                  _hover={{ bgColor: '#ECE9D2' }}
                  onClick={() => history.push('/myprofile/2')}
                >
                  Notifications
                </MenuItem>
                <MenuItem
                  _hover={{ bgColor: '#ECE9D2' }}
                  onClick={() => history.push('/myprofile/4')}
                >
                  Orders
                </MenuItem>
                <MenuItem
                  isDisabled
                  _hover={{ bgColor: '#ECE9D2' }}
                  onClick={() => history.push('/myprofile/4')}
                >
                  My Garage
                </MenuItem>
                <MenuItem
                  _hover={{ bgColor: '#ECE9D2' }}
                  onClick={() => history.push('/myprofile/6')}
                >
                  My Profile
                </MenuItem>
                <MenuItem
                  _hover={{ bgColor: '#ECE9D2' }}
                  onClick={() => history.push('/customercare')}
                >
                  Customer Care
                </MenuItem>
                <MenuItem
                  _hover={{ bgColor: '#ECE9D2' }}
                  onClick={() => {
                    props.refreshPage();
                  }}
                >
                  Log Out
                </MenuItem>
              </MenuList>
            ) : (
              <MenuList pt={0} pb={0}>
                {/* <MenuItem isDisabled _focus={{ bgColor: 'none' }} _hover={{ bgColor: '#ECE9D2' }}
                                    onClick={() => history.push("/myprofile/0")}> Saved List</MenuItem>
                                <MenuItem isDisabled _hover={{ bgColor: '#ECE9D2' }}
                                    onClick={() => history.push("/myprofile/2")}>Notifications</MenuItem>
                                <MenuItem isDisabled _hover={{ bgColor: '#ECE9D2' }}
                                    onClick={() => history.push("/myprofile/4")}>Orders</MenuItem>
                                <MenuItem isDisabled _hover={{ bgColor: '#ECE9D2' }} onClick={() => history.push("/myprofile/4")}
                                >My Garage</MenuItem>
                                <MenuItem isDisabled _hover={{ bgColor: '#ECE9D2' }}
                                    onClick={() => history.push("/myprofile/6")}>My Profile</MenuItem> */}
                <MenuItem
                  _focus={{ bgColor: 'none' }}
                  _hover={{ bgColor: '#ECE9D2' }}
                  onClick={() => history.push('/customercare')}
                >
                  Customer Care
                </MenuItem>
                <MenuItem
                  _hover={{ bgColor: '#ECE9D2' }}
                  onClick={() => props.closeSignIn()}
                >
                  Sign in
                </MenuItem>
              </MenuList>
            )}
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  );
};
