import React, { useEffect, useState, useRef } from 'react';

import { Box, Button, Flex, Select, VStack } from '@chakra-ui/react';
import { useToast, useDisclosure, Text } from '@chakra-ui/react';
import { CgClose } from 'react-icons/cg';

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  CloseButton,
} from '@chakra-ui/react';
import { API_URL } from '../../../services/api';
import { get, post } from '../../../services/api';
//toast

import {
  FaInfoCircle,
  FaCheckCircle,
  FaExclamationCircle,
} from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';

export const NotificationsMob = (props) => {
  //Alert Dialog PopUp
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [noti, setNoti] = useState([]);
  const [delData, setDelData] = useState({});

  //toast
  const Swal = require('sweetalert2');

  const Toast2 = (title, theme, color, iconColor) => {
    Swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      autofocus: false,
      toast: true,
      animation: true,
      timer: 5000,
      // timerProgressBar: true,
      iconColor: iconColor,
      icon: theme,
      title: title,
      color: color,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
  };

  const getNoti = () => {
    get('/api/v2/listnotifications/')
      .then((res) => {
        setNoti(res?.data);
      })
      .catch((err) => {
        setNoti({});
      });
  };
  const markAsRead = (items) => {
    post('/api/v2/updatenotifications/', { id: items['_id']['$oid'] })
      .then((res) => {
        getNoti();
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getNoti();
  }, []);

  return (
    <Flex
      bgColor="white"
      flexDirection="column"
      alignItems="center"
      minH="77vh"
      width={'100%'}
    >
      <Text className="mob_address_head_font" mt="30px">
        Notifications
      </Text>
      {noti &&
        noti.map((items) => {
          return (
            <Flex flexDirection="column" mt="30px" mb="30px" width="100%">
              <Flex className="mob_markCross">
                {items.isRead == false ? (
                  <div
                    className="mob_mark-as-read-o8tpQI nunitosans-normal-mountain-mist-1"
                    onClick={() => markAsRead(items)}
                  >
                    Mark as read
                  </div>
                ) : (
                  ''
                )}

                <CgClose
                  color="grey"
                  style={{ cursor: 'pointer', fontSize: '14px' }}
                  onClick={() => {
                    setDelData(items);
                    onOpen();
                  }}
                />
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                marginLeft={'10px'}
              >
                <div className="mob_place-lottie-here-o8tpQI">
                  {items.isRead == false ? (
                    <lottie-player
                      autoplay=""
                      background="transparent"
                      loop=""
                      speed="1"
                      src={
                        'https://api.brakeup.in/api/v1/json/Notication_Bulb.json'
                      }
                    ></lottie-player>
                  ) : (
                    ''
                  )}
                </div>
                <div className="mob_contrary-to-popular-o8tpQI nunitosans-normal-shark">
                  {items.message}
                </div>
              </Flex>
            </Flex>
          );
        })}

      {/* // delete popup */}
      <AlertDialog
        motionPreset="slideInBottom"
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size="md"
        autoFocus={false}
      >
        <AlertDialogOverlay />

        <AlertDialogContent bgColor="" borderRadius={'1px'}>
          <AlertDialogCloseButton />
          <Flex justifyContent="space-evenly" alignItems={'center'}>
            <Flex height={'80px'} width="80px">
              <lottie-player
                src={'https://api.brakeup.in/api/v1/json/Delete_Basket.json'}
                background="transparent"
                speed="1"
                autoplay
                loop
              ></lottie-player>
            </Flex>
            <Flex flexDirection="column">
              <AlertDialogBody padding="20px">
                <Text
                  textAlign="flex-end"
                  fontWeight="bolder"
                  className="mob_ alertfont nunitosans-normal-shark"
                  mb="20px"
                >
                  Delete this Notification?
                </Text>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  className="mob_nunitosans-normal-shark"
                  size={'sm'}
                  bgColor="var(--white)"
                  onClick={() => onClose()}
                  borderRadius="3px"
                  boxShadow="0px 3px 6px #00000029"
                >
                  Cancel
                </Button>

                <Button
                  className="mob_nunitosans-normal-shark"
                  size={'sm'}
                  ml={3}
                  borderRadius="3px"
                  boxShadow={'0px 3px 6px #330d2f29'}
                  bgColor={'var(--white-rock-2)'}
                  onClick={() => {
                    post('/api/v2/deletenotifications/', {
                      id: delData['_id']['$oid'],
                    })
                      .then((res) => {
                        onClose();
                        Toast2('Notification Removed !', 'error', '#f22020');

                        setDelData({});
                        getNoti();
                      })
                      .catch((err) => {});
                  }}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </Flex>
          </Flex>
        </AlertDialogContent>
      </AlertDialog>
    </Flex>
  );
};
