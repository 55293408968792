import React, { useState, useEffect } from 'react';
import logo from '../../assets/sarath_IMG/Brakeup Square Logo.png';
import { get, post } from '../../services/api';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCartCount } from '../../stateManagement/user/cartCountSlice';
import { setAddressess } from '../../stateManagement/user/showAddressSlice';
import { setSelectedAddress } from '../../stateManagement/user/showAddressSlice';

import {
  setpaymentPriceDetails,
  setcartItemss,
} from '../../stateManagement/payment/paymentDetails';

import { Meta, UUID } from '../../services/Meta-Pixel';
import { Cart_Add2CartGtag, Cart_Save4LaterGtag } from '../../services/G-Tag';
import loadFacebookPixels from '../../services/loadFacebookPixel';
import { CartNew } from './Cart';

//Toast

const Swal = require('sweetalert2');

export const Toast2 = (title, theme, color, iconColor) => {
  Swal.fire({
    showConfirmButton: false,
    showCloseButton: true,
    autofocus: false,
    toast: true,
    animation: true,
    timer: 5000,
    // timerProgressBar: true,
    // icon: "info",
    iconColor: iconColor,
    icon: theme,
    title: title,
    color: color,
    width: window.innerWidth >= 800 ? '25%' : '',

    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
};

export const Cart = (props) => {
  // const [page, setPage] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [saveItems, setSaveItems] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [bestFitData, setBestFitData] = useState([]);

  const [priceDts, setPriceDts] = useState({});
  const [bestItemsKey, setbestItemsKey] = useState(false);

  const [pincodeLocationcart, setpincodeLocationcart] = useState('');
  const [cartID, setcartID] = useState('');
  // const [editedAddress, seteditedAddress] = useState('');
  const [isOutOfStock, setisOutOfStock] = useState(false);
  const [isanonymous, setisanonymous] = useState(true);
  const [promoAppliedData, setPromoAppliedData] = useState({});
  // const [isCODdisabled, setCODdisabled] = useState(false);

  const [isOpenSuccess, setIsOpenSuccess] = useState(false);

  //PROMOCODE
  const [promocode, setpromocode] = useState('');
  const [promoError, setpromoError] = useState('');
  const [promoSuccessCode, setpromoSuccessCode] = useState(null);

  let history = useHistory();
  const dispatch = useDispatch();
  const access = useSelector((state) => state.user.access);
  const updateCart = useSelector((state) => state.count.updateCart);
  const selectedAddress = useSelector((state) => state.address.SelectedAddress);
  const userName = useSelector((state) => state.user.name);
  const userMobile = useSelector((state) => state.user.mobile);
  const userEmail = useSelector((state) => state.user.email);

  loadFacebookPixels();

  const [isSignIn, setIsSignIn] = useState(false);
  const [showOtpSignIn, setShowOtpSignIn] = useState(false);
  const closeSignIn = () => {
    const token =
      JSON.parse(localStorage.getItem('token')).data.credAuthResult || {};
    if (token.type === 'anonymous') {
      closeModal();
      return;
    }
    get('/api/v2/products/cartitemlist/')
      .then((res) => {
        if (res.data) {
          // setPage(1);
          const id = res.data[0].cart_id['$oid'];
          setcartID(id);
          return id;
        }
        dispatch(setCartCount(res?.totalcount));
        dispatch(setcartItemss(res?.data));
        setCartItems(res?.data);
      })
      .then((id) => {
        get(`/api/v2/products/cart/price_details/?cart_id=${id}`)
          .then((res) => {
            let price = res?.data[0];
            price.cart_id = id;

            let float_price =
              -price.delivery_discount + price.promo_discount + price.discount;
            price.total_discount = float_price.toFixed(0);
            let calc_total =
              price.subtotal -
              price.promo_discount +
              (price.delivery_discount == 0 ? price.delivery_charge : 0);
            price.total = calc_total.toFixed(0);
            price.delivery_charge =
              price.delivery_charge && price.delivery_charge.toFixed(0);
            price.delivery_discount =
              price.delivery_discount && price.delivery_discount.toFixed(0);

            price.brakeup_part_nos = res?.brakeup_part_nos;
            setPriceDts(price);
            dispatch(setpaymentPriceDetails(price));
            setpromoSuccessCode(res?.data[0].promo_id);
            return price;
          })
          .then((priceData) => {
            const token = JSON.parse(localStorage.getItem('token')).data
              .credAuthResult;
            const data = { ...priceData };
            data.prod_id = promoAppliedData?.prod_id ?? '';
            data.pro_price = promoAppliedData?.pro_price ?? 0;
            if (token?.type == 'Access') {
              post('/api/v2/products/update_cartprice/', data)
                .then((res) => {
                  // setPage(2);
                  closeModal();
                  history.push(`/address`);
                })
                .catch((err) => {
                  console.log('error ', err?.response);
                });
            }
          });
      })
      .catch((err) => {
        dispatch(setCartCount(0));
        dispatch(setcartItemss([]));
        setCartItems([]);
        Toast2('Please try again', 'info', '#414bdb', '#414bdb');
      })
      .finally(() => {
        setIsSignIn(false);
        setShowOtpSignIn(false);
      });
  };

  const closeModal = () => {
    setIsSignIn(false);
    setShowOtpSignIn(false);
  };

  useEffect(() => {
    getDetails();
    window.scrollTo(0, 0);
  }, [updateCart]);

  const getPrice = (id) => {
    get(`/api/v2/products/cart/price_details/?cart_id=${id}`)
      .then((res) => {
        // console.log(res?.data, "getpriceee");
        let price = res?.data[0];
        price.cart_id = id;
        // price.is_cod_disabled = true;

        let float_price =
          -price.delivery_discount + price.promo_discount + price.discount;
        price.total_discount = float_price.toFixed(0);
        let calc_total =
          price.subtotal -
          price.promo_discount +
          (price.delivery_discount == 0 ? price.delivery_charge : 0);
        price.total = calc_total.toFixed(0);
        price.delivery_charge =
          price.delivery_charge && price.delivery_charge.toFixed(0);
        price.delivery_discount =
          price.delivery_discount && price.delivery_discount.toFixed(0);

        price.brakeup_part_nos = res?.brakeup_part_nos;
        setPriceDts(price);
        dispatch(setpaymentPriceDetails(price));
        setpromoSuccessCode(res?.data[0].promo_id);
      })
      .catch((err) => {
        setPriceDts({});
        dispatch(setpaymentPriceDetails({}));
      });
  };

  const getDetails = () => {
    if (location.pathname == '/buynow/') {
      var url_string = location.href; //window.location.href
      var url = new URL(url_string);
      var c = url.searchParams.get('id');
      // console.log(c, "location");

      getPrice(c);
      // setPage(2);
      history.push(`/address`);
      get('/api/v2/products/product_buynow/')
        .then((res) => {
          setCartItems(res?.data);
          setcartID(res.data[0].cart_id['$oid']);
          dispatch(setcartItemss(res?.data));
        })
        .catch((err) => {
          setCartItems([]);
          dispatch(setcartItemss([]));
        });
    } else {
      get('/api/v2/products/cartitemlist/')
        .then((res) => {
          if (res.data) {
            // setPage(1);
            getPrice(res.data[0].cart_id['$oid']);
            setcartID(res.data[0].cart_id['$oid']);
          }
          dispatch(setCartCount(res?.totalcount));
          dispatch(setcartItemss(res?.data));
          setCartItems(res?.data);
        })
        .catch((err) => {
          dispatch(setCartCount(0));
          dispatch(setcartItemss([]));
          setCartItems([]);
          // setPage(null);
        });
      get('/api/v2/product/approx_cart_date/')
        .then((res) => {
          if (res.data) {
            // setPage(1);
            // getPrice(res.data[0].cart_id["$oid"]);
            // setcartID(res.data[0].cart_id["$oid"]);
          }
          setCartItems(res?.data);
          // dispatch(setcartItemss(res?.data));
        })
        .catch((err) => {
          // setCartItems([]);
          // setPage(null);
          // dispatch(setcartItemss([]));
        });
      get('/api/v2/products/savelater/')
        .then((res) => {
          setSaveItems(res?.data);
        })
        .catch((err) => {
          setSaveItems([]);
        });
    }
  };

  // const deleteAddress = (data) => {
  //   post('/api/v2/products/deleteaddress/', {
  //     address_id: data.id,
  //     user_id: data.user_id,
  //   })
  //     .then((res) => {
  //       getAddress();
  //       Toast2('Address Removed !', 'error', '#f22020');

  //       // ToastMessage("Address Removed !", "error")
  //     })
  //     .catch((err) => {});
  // };
  // const addAddress = (data, value) => {
  //   let url = '/api/v2/products/address/';
  //   if (value) {
  //     data.address_id = value;
  //     url = '/api/v2/products/updateaddress/';
  //   }
  //   post(url, data)
  //     .then((res) => {
  //       getAddress();
  //       // ToastMessage("Address added Successfully !", "success")
  //       Toast2('Address added Successfully !', 'success', '#089520');

  //       seteditedAddress(value);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.status == 401) {
  //         // ToastMessage("Address already exists !", "error")
  //         Toast2('Address already exists !', 'error', '#f22020');
  //       }
  //     });
  // };
  const changeQuantity = (prod, quantity, type) => {
    if (type == 'cart') {
      let input = {
        cart_id: prod.cart_id['$oid'],
        vend_id: prod.vend_id,
        prod_id: prod.prod_id,
        quantity: parseInt(quantity),
      };
      post('/api/v2/products/updatecart/', input)
        .then((res) => {
          getDetails();
        })
        .catch((err) => {
          getDetails();

          Swal.fire({
            html: `<div style=" width:100%"> <p>Low stock available ! <br/> check <a style="color:#414bdb" >Product Detailpage</a> for other seller.</p></div>`,
            timer: 4000,
            width: '100vw',
            // width: window.innerWidth >= 800 ? "30%" : "",
            showConfirmButton: false,
            didOpen: () => {
              const a = Swal.getHtmlContainer().querySelector('a');
              a.addEventListener('click', () => {
                ToProductDetailPage(prod);
                Swal.close();
              });
            },
          });
        });
    } else {
      let input = {
        vend_id: prod.vend_id,
        prod_id: prod.prod_id,
        quantity: parseInt(quantity),
      };
      post('/api/v2/products/update/savelater/', input)
        .then((res) => {
          getDetails();
        })
        .catch((res) => {});
    }
  };

  const moveProduct = (prod, type) => {
    const eventID = UUID();
    if (type == 'cart') {
      let input = {
        cart_id: prod.cart_id['$oid'],
        vend_id: prod.vend_id,
        prod_id: prod.prod_id,
      };
      post('/api/v2/products/savelater/', input)
        .then((res) => {
          getDetails();
          setIsOpenSuccess(true);
          // Toast2('Item moved to Save for Later!', 'success', '#089520');
          window.fbq(
            'track',
            'AddToWishlist',
            {
              value: prod?.order_prod_price,
              currency: 'INR',
              contents: [
                {
                  id: prod?.brakeup_part_no,
                  quantity: 1,
                },
              ],
            },
            { eventID: eventID }
          );
          let data = {
            currency: 'INR',
            value: prod?.order_prod_price,
            contents: [
              {
                id: prod?.brakeup_part_no,
                quantity: 1,
                delivery_category: 'home_delivery',
              },
            ],
          };
          Meta('AddToWishlist', data, eventID);
          Cart_Save4LaterGtag(prod);
        })
        .catch((res) => {});
    } else {
      let data = {
        product: [
          {
            vend_id: prod.vend_id,
            prod_id: prod.prod_id,
            make_id: prod?.vehicle_details?.make_id ?? '',
            model_id: prod?.vehicle_details?.model_id ?? '',
            year: prod?.vehicle_details?.year ?? '',
            fueltype: prod?.vehicle_details?.fueltype ?? '',
            version: prod?.vehicle_details?.version ?? '',
            delivery_date: prod.delivery_date,
            quantity: prod.quantity,
          },
        ],
        pincode: '110001',
      };
      const eventID = UUID();

      post('/api/v2/products/addtocart/', data)
        .then((res) => {
          getDetails();
          setbestItemsKey(!bestItemsKey);
          setIsOpenSuccess(true);
          // Toast2('Item moved to Cart !', 'success', '#089520');
          window.fbq(
            'track',
            'AddToCart',
            {
              value: prod?.order_prod_price,
              currency: 'INR',
              contents: [
                {
                  id: prod?.brakeup_part_no,
                  quantity: 1,
                },
              ],
            },
            { eventID: eventID }
          );
          let data = {
            currency: 'INR',
            value: prod?.order_prod_price,
            contents: [
              {
                id: prod?.brakeup_part_no,
                quantity: 1,
                delivery_category: 'home_delivery',
              },
            ],
          };
          Meta('AddToCart', data, eventID);
          Cart_Add2CartGtag(prod);
        })
        .catch((err) => {});
    }
  };
  const removeProd = (prod, type) => {
    if (type == 'cart') {
      let input = {
        cart_id: prod.cart_id['$oid'],
        vend_id: prod.vend_id,
        prod_id: prod.prod_id,
      };
      post('/api/v2/products/removefromcart/', input)
        .then((res) => {
          getDetails();
          setbestItemsKey(!bestItemsKey);

          Toast2('Item Removed !', 'error', '#f22020');

          // ToastMessage("Item Removed !", "error")
        })
        .catch((res) => {});
    } else {
      let input = {
        vend_id: prod.vend_id,
        prod_id: prod.prod_id,
      };
      post('/api/v2/products/delete/saveditems/', input)
        .then((res) => {
          getDetails();
          setbestItemsKey(!bestItemsKey);

          Toast2('Item Removed !', 'error', '#f22020');

          // ToastMessage("Item Removed !", "error")
        })
        .catch((res) => {});
    }
  };

  //BEST FIT ITEMS

  // const getbestItems = (url) => {
  //   get(url).then(res => {
  //     if (bestItemsKey.length != res.total_count) {
  //       let data = [...bestItemsKey, ...res.data];

  //       setbestItemsKey(data);
  //     }
  //   }).catch(err => {
  //     setbestItemsKey([]);
  //   })
  // }
  // const onRectClick = () => {
  //   let url = '/api/v2/products/cart/bestfit/';
  //   if (bestItemsKey) {
  //     const lastItem = bestItems[bestItems.length - 1];
  //     url = `${url}?previousId=${lastItem["_id"]["$oid"]}`;
  //     url = `${url}&limit=10`;
  //     url = `${url}&order=1`;
  //   }
  //   getbestItems(url);
  // }

  //Alert change PINCODE in addresspage

  const [displayPINalert, setdisplayPINalert] = useState(false);

  // const {
  //   isOpen: isOpenPINalert,
  //   onOpen: onOpenPINalert,
  //   onClose: onClosePINalert,
  // } = useDisclosure()

  // const proceedToPayment = (type) => {
  //   const tokenpin = JSON.parse(localStorage.getItem('pincode'));

  //   if (selectedAddress && Object.keys(selectedAddress).length > 0) {
  //     if (selectedAddress.pincode == tokenpin) {
  //       // setPage(3);
  //       get(`/api/v2/products/cod_users/`)
  //         .then((res) => {
  //           // console.log(res?.data[0]?.is_coddisabled, "cod_userscod_users");
  //           setCODdisabled(res?.data[0]?.is_coddisabled);
  //         })
  //         .catch((err) => {});
  //     } else if (displayPINalert) {
  //       let addressid = {
  //         address_id: selectedAddress['_id']['$oid'],
  //       };
  //       post(`/api/v2/products/updateaddress/`, addressid)
  //         .then((res) => {
  //           localStorage.setItem(
  //             'pincode',
  //             JSON.stringify(selectedAddress.pincode)
  //           );
  //           // setPage(3);
  //           setdisplayPINalert(false);
  //           get(`/api/v2/products/cod_users/`)
  //             .then((res) => {
  //               setCODdisabled(res?.data[0]?.is_coddisabled);
  //             })
  //             .catch((err) => {});
  //         })
  //         .catch((err) => {});
  //     } else {
  //       // onOpenPINalert()
  //       setdisplayPINalert(true);
  //       window.scrollTo(0, 0);
  //     }
  //   } else {
  //     Toast2('Please Add Address !', 'info', '#414bdb', '#414bdb');
  //   }
  // };

  useEffect(() => {
    setisOutOfStock(false);
    cartItems.map((products) => {
      // console.log(products.stock_status,"products.stock_statusproducts.stock_status");
      if (products.stock_status == 'out of stock') {
        return setisOutOfStock(true);
      }
    });
    // console.log(isOutOfStock, "priceDtspriceDts");
  }, [cartItems]);

  const updatePrice = () => {
    const token = JSON.parse(localStorage.getItem('token')).data.credAuthResult;
    let data = { ...priceDts };
    data.prod_id = promoAppliedData?.prod_id ?? '';
    data.pro_price = promoAppliedData?.pro_price ?? 0;
    localStorage.setItem('action', 'address');

    if (token?.type == 'Access') {
      if (cartItems.length == 0) {
        Toast2('Please add items to the cart!', 'info', '#414bdb', '#414bdb');
        return;
      }
      if (isOutOfStock) {
        Toast2('Please remove Out of Stock item from cart', 'error', '#f22020');
      } else {
        post('/api/v2/products/update_cartprice/', data)
          .then((res) => {
            // setPage(2);
            history.push(`/address`);
            getPrice(cartID);
          })
          .catch((err) => {
            console.log('error ', err?.response);
          });
      }
    } else if (window.innerWidth >= 800) {
      setShowOtpSignIn(true);
      setIsSignIn(true);
    } else {
      localStorage.setItem('action', 'address');
      history.push(`/loginsignup`);
      Toast2('Please Sign in !', 'info', '#414bdb', '#414bdb');
    }
  };

  const getAddress = () => {
    get('/api/v2/products/address/')
      .then((res) => {
        dispatch(setAddressess(res.data));
        if (res?.data.length == 1) {
          // console.log(res.data[0], "setAddressessres.data[0]res.data[0]");
          dispatch(setSelectedAddress(res.data[0]));
        } else {
          res?.data.map((items) => {
            if (items.isMain) {
              dispatch(setSelectedAddress(items));
            }
          });
        }
      })
      .catch((err) => {
        dispatch(setAddressess([]));
      });
  };

  const onlinePayment = () => {
    let data = {
      cart_id: cartItems[0]['cart_id']['$oid'],
      address_id: selectedAddress?._id['$oid'],
      payment_type: 'Online',
      promo_id: promoSuccessCode,
    };
    post('/api/v2/products/buynow/', data)
      .then((res) => {
        openRazorPayWindow(res?.razorpay_details?.id);
        setpaymentType(data.payment_type);
        setorderDetails(res);
        dispatch(setpaymentTypee(data.payment_type));
        dispatch(setorderDetailss(res));
      })
      .catch((err) => {});
  };
  const paymentSuccess = (data) => {
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    data.cart_id = cartItems[0]['cart_id']['$oid'];
    data.user_id = token?.data?.credAuthResult?.id;
    // dispatch(setpaymentPriceDetails(priceDts))
    post('/api/v2/payment/signature_check/', data)
      .then((res) => {
        // setPage(4);
        window.localStorage.removeItem('pincode');
        window.localStorage.removeItem('appliedpromocode');
        history.push('/order-success');
      })
      .catch((err) => {});
  };
  const openRazorPayWindow = (order_id) => {
    var options = {
      key: 'rzp_live_X3LkoOH3mjBza2', // Enter the Key ID generated from the Dashboard
      amount: '2500', // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: 'INR',
      name: 'Brakeup',
      description: 'Brakeup Online Transaction',
      image: logo,
      order_id: order_id,
      handler: (response) => {
        paymentSuccess(response);
      },
      prefill: {
        name: userName ? userName : '',
        email: userEmail ? userEmail : '',
        contact: userMobile ? userMobile : '',
      },
      notes: {
        address: 'BrakeUp, India',
      },
      theme: {
        color: '#ffe600',
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on('payment.authorized', function (response) {
      // console.log(response, "Razorpay authorized");
    });
    rzp1.on('payment.failed', function (response) {
      // if (response.error?.description == "Network error"
      //   || response?.error?.reason == "input_validation_failed") {
      //   setPage(10)
      // } else {
      //   setPage(5)
      // }
      // alert(response.error.code);
      //   alert(response.error.description);
      //   alert(response.error.source);
      //   alert(response.error.step);
      //   alert(response.error.reason);
      //   alert(response.error.metadata.order_id);
      //   alert(response.error.metadata.payment_id);
      // console.log(response.error, "Razorpay");
    });
  };

  // const placeOrder = (otp) => {
  //   let data = {
  //     cart_id: cartItems[0]['cart_id']['$oid'],
  //     address_id: selectedAddress?._id['$oid'],
  //     payment_type: 'COD',
  //     otp: otp,
  //     promo_id: promoSuccessCode,
  //   };

  //   let pricedata = { ...priceDts };
  //   pricedata.total = priceDts.shiprocket_cod_total_amount;
  //   dispatch(setpaymentPriceDetails(pricedata));
  //   setPriceDts(pricedata);

  //   post('/api/v2/products/buynow/', data)
  //     .then((res) => {
  //       // console.log(res, "/api/v2/products/buynow/");

  //       // setPage(4);
  //       setpaymentType(data.payment_type);
  //       setorderDetails(res);
  //       dispatch(setpaymentTypee(data.payment_type));
  //       dispatch(setorderDetailss(res));
  //       window.localStorage.removeItem('pincode');
  //       window.localStorage.removeItem('appliedpromocode');
  //       history.push('/order-success');
  //     })
  //     .catch((err) => {});
  // };

  //PINCODE From cart page

  // const cartPincode = (pin) => {
  //   if (pin.length == 6) {
  //     let pincode = { pincode: pin };
  //     post('/api/v2/product/approx_cart_date/', pincode).then((res) => {
  //       setCartItems(res?.data);
  //       setpincodeLocationcart(res?.pincode_loc);
  //     });
  //   }
  // };

  const checkIfAnonymous = () => {
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    if (token.data.credAuthResult.type == 'anonymous') {
      setisanonymous(false);
      if (window.innerWidth >= 800) {
        setIsSignIn(true);
      } else {
        history.push(`/loginsignup`);
        // ToastMessage("Please Sign in !", "error")
        Toast2('Please Sign in !', 'info', '#414bdb', '#414bdb');
        // console.log("checkIfAnonymouscheckIfAnonymous");
      }
    } else {
      setisanonymous(true);
    }
  };

  useEffect(() => {
    const promotoken = JSON.parse(localStorage.getItem('appliedpromocode'));
    // console.log(promotoken, "promotokenpromotoken");

    if (promotoken && promotoken != '') {
      CheckPromo(promotoken);
      setpromocode(promotoken);
    }
  }, [cartItems]);

  const CheckPromo = (code, key) => {
    // console.log(code, key, "CheckPromoCheckPromo");
    // checkIfAnonymous()

    const token = JSON.parse(localStorage.getItem('token') || '{}');
    if (cartItems.length != 0) {
      let data = {
        cart_id: cartItems[0]['cart_id']['$oid'],
        user_id: token?.data?.credAuthResult?.id,
        promo_code: code,
      };

      post('/api/v2/promocode/promo_verification/', data)
        .then((res) => {
          getPrice(cartID);
          setpromoError(res?.promocode);
          setPromoAppliedData(res);

          localStorage.setItem('appliedpromocode', JSON.stringify(code));
        })
        .catch((err) => {
          getPrice(cartID);
          if (key && key == 'removed') {
            setpromoError('');
          } else {
            setpromoError(err?.response?.data?.message);
          }
          localStorage.setItem('appliedpromocode', JSON.stringify(''));
          setPromoAppliedData({});
        });
    }
  };

  const ToProductDetailPage = (products) => {
    history.push(
      `/product/${products?.prod_id}${
        products.vehicle_details?.make_id
          ? '/' +
            products.vehicle_details?.make_id +
            '/' +
            products.vehicle_details?.model_id +
            '/' +
            products.vehicle_details?.year +
            (products.vehicle_details?.version
              ? '/' + products.vehicle_details?.version
              : '') +
            (products.vehicle_details?.fueltype
              ? '/' + products.vehicle_details?.fueltype
              : '')
          : ''
      }${
        products.vehicle_details?.type
          ? '/' + products.vehicle_details?.type
          : ''
      }`
    );
  };

  const getRecentlyViewed = () => {
    get(`/api/v2/product/recently_viewed/`)
      .then((res) => {
        setRecentData(res.data);
      })
      .catch((err) => {
        console.log('error ', err.response);
        setRecentData([]);
      });
  };
  const getBestFitItems = () => {
    get(`/api/v2/products/cart/bestfit/`)
      .then((res) => {
        setBestFitData(res.data);
      })
      .catch((err) => {
        setBestFitData([]);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      getBestFitItems();
    }, 2000);
    setTimeout(() => {
      getRecentlyViewed();
    }, 3000);
  }, []);

  return (
    <React.Fragment>
      <CartNew
        cartItems={cartItems}
        saveItems={saveItems}
        priceDts={priceDts}
        changeQuantity={changeQuantity}
        moveProduct={moveProduct}
        removeProd={removeProd}
        recentData={recentData}
        bestFitData={bestFitData}
        isOpenSuccess={isOpenSuccess}
        setIsOpenSuccess={setIsOpenSuccess}
        updatePrice={updatePrice}
        // onRectClick={onRectClick}
        bestItemsKey={bestItemsKey}
        isSignIn={isSignIn}
        showOtpSignIn={showOtpSignIn}
        closeSignIn={closeSignIn}
        closeModal={closeModal}
        pincodeLocationcart={pincodeLocationcart}
        CheckPromo={CheckPromo}
        promocode={promocode}
        promoError={promoError}
        setpromoError={setpromoError}
        checkIfAnonymous={checkIfAnonymous}
        isanonymous={isanonymous}
      />
    </React.Fragment>
  );
};
