import React, { useRef, useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  Box,
  Divider,
  Button,
  Flex,
  Image,
  Text,
  Grid,
  GridItem,
  IconButton,
  Spacer,
} from '@chakra-ui/react';
import { API_URL } from '../../services/api';

import { IoChevronForward, IoChevronBack } from 'react-icons/io5';
import { AddIcon } from '@chakra-ui/icons';
import { FaStar } from 'react-icons/fa';

import CustomSelect from '../FilterPage/CustomSelect';

import Car from '../../assets/sarath_IMG/carBlack1.png';
import Quotebg from '../../assets/sarath_IMG/Quote_background_3.png';
import hpbg from '../../assets/sarath_IMG/BaseofHomepage-4.png';
import singleroundlogo from '../../assets/footer_img/Squar single rotor brakeup.png';
// import EnquiryPopover from '../../ZAds/EnquiryPopover';
import closemark from '../../assets/sarath_IMG/x.svg';

//SHOP BY BRANDS
import pad from '../../assets/sarath_IMG/PadsB.png';
import rotor from '../../assets/sarath_IMG/RotorB.png';
import fluid from '../../assets/sarath_IMG/FluidB.png';
import other from '../../assets/sarath_IMG/Other-ProductsB.png';

//HIGHLIGHTS
import high1 from '../../assets/sarath_IMG/Find_top_brands.png';
import high2 from '../../assets/sarath_IMG/GenuineProducts-01.png';
import high3 from '../../assets/sarath_IMG/Easy Compatability.svg';
import high4 from '../../assets/sarath_IMG/Fast_FreeDelivery.png';
import high5 from '../../assets/sarath_IMG/DiscountDeals.png';

import p1 from '../../assets/sarath_IMG/brakepad-01-in-product-detail-page-5@1x.png';
import p2 from '../../assets/sarath_IMG/Brake Fluids@2x.png';
import p3 from '../../assets/sarath_IMG/brakepad-02-in-product-detail-page-5@1x.png';
import p4 from '../../assets/sarath_IMG/brakepad-generic-image-in-all-pages-5@1x.png';
import p5 from '../../assets/sarath_IMG/brakepad-main-image-in-product-detail-page-5@1x.png';
import p6 from '../../assets/sarath_IMG/brakepad-03-in-product-detail-page-5@1x.png';

import logo1 from '../../assets/sarath_IMG/BMW_Logo.png';
import logo2 from '../../assets/sarath_IMG/Porsche_Logo.png';
import logo3 from '../../assets/sarath_IMG/Bentley_Logo.png';
import logo4 from '../../assets/sarath_IMG/Lamborghini_Logo.png';
import logo5 from '../../assets/sarath_IMG/Maserati_Logo.png';
import logo6 from '../../assets/sarath_IMG/Jaguar_Logo.png';

//ADVERTISEMENT
import Ad1 from '../../assets/sarath_IMG/Banner-1-Web21.jpg';
import Ad2 from '../../assets/sarath_IMG/Banner-2-Web21.jpg';
import Ad3 from '../../assets/sarath_IMG/Banner-3-Web21.jpg';
import Ad4a from '../../assets/sarath_IMG/Banner-4-Web2.jpg';
import Ad4b from '../../assets/sarath_IMG/Banner-5-Web2.jpg';
import Ad5 from '../../assets/sarath_IMG/Banner-6-Web2.jpg';
import Ad6a from '../../assets/sarath_IMG/Banner-7-Web2.jpg';
import Ad6b from '../../assets/sarath_IMG/Banner-8-Web21.jpg';
import Ad7 from '../../assets/sarath_IMG/Banner-9-Web2.jpg';
import Ad8a from '../../assets/sarath_IMG/Banner-10-Web2.jpg';
import Ad8b from '../../assets/sarath_IMG/Banner-11-Web2.jpg';
import { AdsWeb } from '../../ZAds/AdsWeb';
import contactUs from '../../assets/sarath_IMG/Customer-care-banner-web-low.jpg';
import { useHistory } from 'react-router-dom';

export const HomePageWeb = (props) => {
  // console.log(props, "HomePageWeb");

  let history = useHistory();

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    register,
    unregister,
  } = useForm({
    mode: 'onChange',
  });

  const [hideRightIcon, sethideRightIcon] = useState(false);
  const [hideLeftIcon, sethideLeftIcon] = useState(true);

  const ref = useRef();
  useEffect(() => {
    // if (ref.current.scrollLeft == 0) {
    // sethideLeftIcon(true)
    // }
  }, []);

  // const [isEnquiryPopoverOpen, setIsEnquiryPopoverOpen] = useState(false);
  // const [isMessageDisplayed, setIsMessageDisplayed] = useState(true);

  // const openEnquiryPopover = () => {
  //   setIsEnquiryPopoverOpen(true);
  //   setIsMessageDisplayed(false);
  // };

  // const closeEnquiryPopover = () => {
  //   setIsEnquiryPopoverOpen(false);
  //   setIsMessageDisplayed(true);
  // };

  // const closeMessage = () => {
  //   setIsMessageDisplayed(false);
  // };

  const scrollRight = (scrollOffset) => {
    ref.current.scrollLeft -= scrollOffset;
    // console.log(ref.current.scrollLeft, "ref.current.scrollLeft ");
    if (ref.current.scrollLeft == 0) {
      sethideLeftIcon(true);
    }
  };
  const scrollLeft = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
    // console.log(ref.current.scrollLeft, "ref.current.scrollLeft ");
    if (ref.current.scrollLeft != 0) {
      sethideLeftIcon(false);
    }
  };

  let items = [];
  props.topselldata &&
    props.topselldata.map((item) => {
      items.push(
        <GridItem
          padding="1vw"
          margin=".2vw"
          cursor={'pointer'}
          onClick={() => props.onClickTopSell(item)}
        >
          <Box
            className="item_box recent_box"
            bg="#FFFFFF"
            height="max-content"
            _hover={{
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4)',
              transitionDelay: '.5s',
            }}
            mr={5}
            mt={3}
            mb={3}
            p="1rem"
            borderRadius="lg"
          >
            {/* <Flex justify='flex-end' className='desktop_only'  >
                <IconButton className='plus_icon' size='md' textAlign='center' fontSize='7px' bgColor='#FFFFFF' borderRadius='4px' boxShadow='0px 0px 4px #00000033' aria-label='Search database' icon={<AddIcon />} />
            </Flex> */}
            <Box
              className="recent_image image_transition"
              p={1}
              justifyContent="center"
              backgroundImage={`url('${
                item?.prod_details?.prod_image &&
                item?.prod_details?.prod_image[0]
              }')`}
              backgroundPosition="center"
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
            >
              <Flex justify="flex-end" className="plus_icon_recent">
                <IconButton
                  className="plus_icon"
                  size="md"
                  textAlign="center"
                  fontSize="7px"
                  bgColor="#FFFFFF"
                  borderRadius="4px"
                  boxShadow="0px 0px 4px #00000033"
                  aria-label="Search database"
                  icon={<AddIcon />}
                />
              </Flex>
            </Box>
            <Box>
              <Spacer />
              <div style={{ visibility: 'hidden' }}>
                <Grid className="rating_box" justifyItems="flex-end">
                  <Flex
                    width="auto"
                    justifyContent="end"
                    alignItems="center"
                    className="ratingbox"
                    bgColor={3.0 ? '#279043' : '#905827'}
                  >
                    <Box>3.4</Box>
                    <Box pl="2px" pr="2px">
                      {' '}
                      <FaStar />
                    </Box>
                  </Flex>
                  <Box className="reviewCount"> 300 Ratings</Box>
                </Grid>
              </div>
              <Flex alignItems="end" className="companyname">
                {item?.prod_details?.brand}
              </Flex>

              <Box className="title">{item?.prod_details?.prod_name}</Box>
              <Flex alignItems="center" h="30px" overflow="hidden">
                <Image
                  className="recentlogo"
                  src={item?.vehicle_details?.make_image[0]}
                />
                <Text className="titledetails">
                  {' '}
                  {item?.vehicle_details?.model} {item?.vehicle_details?.year}{' '}
                </Text>
              </Flex>
              <Flex
                className="item_box_price"
                whiteSpace="nowrap"
                alignItems="center"
                width="100%"
                justifyContent="flex-start"
              >
                <Box className="sellingPrice" fontWeight={500}>
                  Rs. {item?.vendor_details?.selling_price}
                </Box>
                {item?.vendor_details?.discount ? (
                  item?.vendor_details?.discount.toFixed(0) == 0 ? (
                    ''
                  ) : (
                    <>
                      <Box pl={2} className="costPrice">
                        ₹ {item?.prod_details?.mrp}
                      </Box>
                      <Box pl={2} className="discountPercentage">
                        (
                        {item?.vendor_details?.discount
                          ? item?.vendor_details?.discount.toFixed(0)
                          : ''}
                        % OFF)
                      </Box>
                    </>
                  )
                ) : (
                  ''
                )}
              </Flex>
            </Box>
          </Box>
        </GridItem>
      );
    });

  return (
    <Box
      bgImage={hpbg}
      backgroundSize="contain"
      p="0 10vw 7vw 10vw"
      backgroundRepeat="no-repeat"
      backgroundPosition=""
      position={'relative'}
      overflow={'hidden'}
    >
      <Flex flexDirection="column" alignItems="center">
        <Flex
          width="100%"
          alignSelf="flex-start"
          pt="7vw"
          height={window.innerHeight > window.innerWidth ? '' : '100vh'}
          className="hpWebLandingSection"
        >
          <Flex width="55%" mb="5%">
            <Box
              className="hpSearchAnim"
              bgColor="#fae10a"
              borderRadius={'10px'}
              padding="2vw"
              height="max-content"
              minWidth="260px"
              maxWidth="500px"
              width="100%"
            >
              <form>
                <Box className="hpSelect">
                  <CustomSelect
                    id="CustomSelectMake"
                    inputStyle={props.inputStyle}
                    defaultValue={null}
                    name={'make'}
                    options={props.make}
                    value={props.filter?.make?.label}
                    onChange={props.onChange}
                    placeholder="Make"
                    size={props.size}
                    boxheight={'280px'}
                  />
                  {/* {props.error ? <span className="hpSelectError">Make is required.</span> : ""} */}
                </Box>

                <Box className="hpSelect">
                  <CustomSelect
                    id="CustomSelectModel"
                    inputStyle={props.inputStyle}
                    defaultValue={null}
                    name={'model'}
                    options={props.model}
                    value={props.filter?.model?.label}
                    onChange={props.onChange}
                    // {...register("model",{ required: true })}
                    rules={{ required: true }}
                    placeholder="Model"
                    size={props.size}
                    boxheight={'230px'}
                  />
                  {/* {errors.model && <span style={{ position: 'absolute', fontSize: '12px', color: "red" }}>Model is required.</span>} */}
                </Box>

                <Box className="hpSelect">
                  <CustomSelect
                    id="CustomSelectYear"
                    inputStyle={props.inputStyle}
                    defaultValue={null}
                    name={'year'}
                    options={props.year}
                    value={props.filter?.year?.label}
                    onChange={props.onChange}
                    // {...register("year",{ required: true })}
                    rules={{ required: false }}
                    placeholder="Year of manufacture"
                    size={props.size}
                  />
                  {/* {errors.year && <span style={{ position: 'absolute', fontSize: '12px', color: "red" }}>Year is required.</span>} */}
                </Box>

                {props.version.length != 0 && (
                  <Box className="hpSelect">
                    <CustomSelect
                      inputStyle={props.inputStyle}
                      defaultValue={null}
                      name={'version'}
                      options={props.version}
                      value={props.filter?.version?.label}
                      onChange={props.onChange}
                      rules={{ required: false }}
                      placeholder="Version"
                      size={props.size}
                    />
                    {/* {errors.type && <span style={{ color: "red" }}>Version is required.</span>} */}
                  </Box>
                )}
                <Box className="hpSelect">
                  <CustomSelect
                    inputStyle={props.inputStyle}
                    defaultValue={null}
                    name={'fuel'}
                    // options={[{ "label": "Petrol", id: "Petrol" },
                    // { "label": "Diesel", id: "Diesel" }]}
                    options={props.fuel}
                    onChange={props.onChange}
                    value={props.filter?.fuel?.label}
                    rules={{ required: false }}
                    placeholder="Fuel type"
                    size={props.size}
                  />
                  {/* {errors.fuel && <span style={{ color: "red" }}>Fuel is required.</span>} */}
                </Box>

                <Box className="hpSelect">
                  <CustomSelect
                    inputStyle={props.inputStyle}
                    defaultValue={null}
                    name={'type'}
                    options={[
                      { label: 'Front', id: 'Front' },
                      { label: 'Rear', id: 'Rear' },
                    ]}
                    onChange={props.onChange}
                    value={props.filter?.type?.label}
                    rules={{ required: false }}
                    placeholder="Front/rear"
                    size={props.size}
                  />
                  {/* {errors.type && <span style={{ color: "red" }}>Front/rear is required.</span>} */}
                </Box>

                <Flex
                  width="100%"
                  className="hpSelect"
                  style={{ cursor: 'pointer' }}
                  onClick={handleSubmit((d) => props.onSearch(d))}
                >
                  <Button
                    isLoading={props.SearchButtonLOader}
                    className="hpbtnsearch"
                    width="100%"
                    borderRadius="5px"
                    color="white"
                    bgColor={
                      props.isSelected3
                        ? '#414bdb'
                        : props.isSelected2
                        ? '#6a71d7'
                        : props.isSelected1
                        ? '#989cd8'
                        : '#b8bada'
                    }
                    _hover={{ bgColor: '#a7acf4' }}
                  >
                    {props.isSelected3
                      ? 'PROCEED'
                      : props.isSelected2
                      ? 'SELECT YEAR'
                      : props.isSelected1
                      ? 'SELECT MODEL'
                      : 'SELECT MAKE'}
                  </Button>
                </Flex>
              </form>
            </Box>
          </Flex>
          <Flex flexDir="column" ml="6vw" width="100%">
            <Text className="hpHeader">The Simple Art of Brake Upgrade</Text>
            <Text className="hpHeaderdata" mt="1vw">
              Welcome to Brakeup.{' '}
              <span className="hpHeaderdataextention2">
                We Proudly Offer a Comprehensive Line of Precisely Shaped and
                High-Quality Rotors, Pads, Fluids &amp; Other Products.
              </span>{' '}
              <span className="hpHeaderdataextention1">
                {' '}
                Choose Your Car Here to See the List of All Your Favorite
                Brands.{' '}
              </span>
            </Text>
            <Box>
              <Image
                className="hpCarImgWeb"
                position="absolute"
                right={0}
                minHeight="350x"
                maxHeight="700px"
                height="40vw"
                src={Car}
              />
            </Box>
          </Flex>
        </Flex>

        <Flex
          bgColor="white"
          mt="5vw"
          boxShadow="0px 0px 99px #00000026"
          width="100%"
          borderRadius="5px"
          padding="3vw 0 3vw 7vw"
          flexDirection="column"
        >
          <Text className="hpsubheads" mb="2vw">
            Products by Category
          </Text>
          <Divider color="#d8d8d8" />
          <Grid
            templateColumns="repeat(4, 1fr)"
            justifyContent="space-evenly"
            paddingTop="3vw"
            marginRight={'6vw'}
          >
            <GridItem
              className=" hbProbc"
              padding="1vw"
              margin="2vw"
              onClick={() =>
                props.onClickCategory({
                  value: 'Brake Pad',
                  name: 'category',
                  label: 'Brake Pad',
                })
              }
            >
              <Image className=" hbProbcImg" src={pad} />
              <Text className=" hbProbcTitles">BRAKE PADS</Text>
            </GridItem>
            <GridItem
              className=" hbProbc"
              padding="1vw"
              margin="2vw"
              onClick={() =>
                props.onClickCategory({
                  value: 'Brake Rotor',
                  name: 'category',
                  label: 'Brake Rotor',
                })
              }
            >
              <Image className=" hbProbcImg" src={rotor} />
              <Text className=" hbProbcTitles">BRAKE ROTOR</Text>
            </GridItem>
            <GridItem
              className=" hbProbc"
              padding="1vw"
              margin="2vw"
              onClick={() =>
                props.onClickCategory({
                  value: 'Brake Fluid',
                  name: 'category',
                  label: 'Brake Fluid',
                })
              }
            >
              <Image className=" hbProbcImg" src={fluid} />
              <Text className=" hbProbcTitles">BRAKE FLUID</Text>
            </GridItem>
            <GridItem
              className=" hbProbc"
              padding="1vw"
              margin="2vw"
              onClick={() =>
                props.onClickCategory({
                  value: 'Other',
                  name: 'category',
                  label: 'Other',
                })
              }
            >
              <Image className=" hbProbcImg" src={other} />
              <Text className=" hbProbcTitles">OTHER PRODUCTS</Text>
            </GridItem>
          </Grid>
        </Flex>

        <Flex mt="5vw" position="relative" boxShadow="0px 0px 99px #00000026">
          <Image src={Ad1} loading="lazy" />
          <Button
            className="hpbtnAdDark"
            _hover={{ bgColor: '#fae10a', color: '#000' }}
            border="2px"
            borderColor="#000"
            position="absolute"
            bottom="10%"
            right="21%"
            bgColor="black"
            color="#fae10a"
            borderRadius="3xl"
            onClick={() =>
              props.onClickAds([
                { value: 'Brembo', name: 'brand', label: 'Brembo' },
                {
                  value: '63087a8178de19ec4482d480',
                  name: 'make',
                  label: 'BMW',
                },
              ])
            }
          >
            Shop Now
          </Button>
        </Flex>

        <AdsWeb
          AD2={true}
          adStyle={{ marginTop: '5vw', boxShadow: '0px 0px 99px #00000026' }}
        />

        <Flex mt="5vw" position="relative" boxShadow="0px 0px 99px #00000026">
          <Image src={contactUs} loading="lazy" />
          <Button
            className="hpbtnAdDark"
            _hover={{ bgColor: '#72b5cf', color: '#000' }}
            border="2px"
            borderColor="#000"
            position="absolute"
            bottom="12%"
            left="33%"
            bgColor="black"
            color="#72b5cf"
            borderRadius="3xl"
            onClick={() => history.push('/customercare')}
          >
            Contact Us
          </Button>
        </Flex>

        <Flex
          bgColor="white"
          mt="5vw"
          boxShadow="0px 0px 99px #00000026"
          width="100%"
          borderRadius="5px"
          padding="3vw 0 3vw 7vw"
          flexDirection="column"
        >
          <Text className="hpsubheads" mb="2vw">
            Shop Products by Brand
          </Text>
          <Divider color="#d8d8d8" />

          {/* <div className="horizontal-scroll-brands-WFc5Wu" >
                        <IoChevronForward fontSize="60px" color="#FBDE00" onClick={() => scroll(250)} />
                    </div> */}
          <Flex width="100%" alignItems="center">
            <Flex
              className="shopByBrandsScroll"
              width="100%"
              flexDirection="column"
              flexWrap="wrap"
              ref={ref}
            >
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/Brembo.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'Brembo',
                    name: 'brand',
                    label: 'Brembo',
                  })
                }
              />
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/EBC.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'EBC',
                    name: 'brand',
                    label: 'EBC',
                  })
                }
              />
              {/* <Image className="hpscroll-group-image" _hover={{ boxShadow: " 0px 0px 10px 2px rgba(0,0,0,0.2)" }} transition={"all 0.2s ease"} src={require("../../assets/ManufacturerLogos/Motherson.png")} onClick={() => props.onClickCategory({ value: "Motherson", name: "brand", label: "Motherson" })} /> */}
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/Bosch.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'Bosch',
                    name: 'brand',
                    label: 'Bosch',
                  })
                }
              />
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/Hiq.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'Hi-Q',
                    name: 'brand',
                    label: 'Hi-Q',
                  })
                }
              />
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/ICER.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'ICER',
                    name: 'brand',
                    label: 'ICER',
                  })
                }
              />
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/Ktek.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'KTEK',
                    name: 'brand',
                    label: 'KTEK',
                  })
                }
              />
              {/* <Image className="hpscroll-group-image" _hover={{ boxShadow: " 0px 0px 10px 2px rgba(0,0,0,0.2)" }} transition={"all 0.2s ease"} src={require("../../assets/ManufacturerLogos/Hella.png")} onClick={() => props.onClickCategory({ value: "Hella", name: "brand", label: "Hella" })} /> */}
              {/* <Image className="hpscroll-group-image" _hover={{ boxShadow: " 0px 0px 10px 2px rgba(0,0,0,0.2)" }} transition={"all 0.2s ease"} src={require("../../assets/ManufacturerLogos/Allied Nippon.png")} onClick={() => props.onClickCategory({ value: "Allied Nippon", name: "brand", label: "Allied Nippon" })} /> */}
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/Meyle.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'Meyle',
                    name: 'brand',
                    label: 'Meyle',
                  })
                }
              />
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/ATE.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'ATE',
                    name: 'brand',
                    label: 'ATE',
                  })
                }
              />
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/TVS Giriling.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'TVS Giriling',
                    name: 'brand',
                    label: 'TVS Giriling',
                  })
                }
              />
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/Supergrip.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'Supergrip',
                    name: 'brand',
                    label: 'Supergrip',
                  })
                }
              />

              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/Brembo.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'Brembo',
                    name: 'brand',
                    label: 'Brembo',
                  })
                }
              />
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/EBC.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'EBC',
                    name: 'brand',
                    label: 'EBC',
                  })
                }
              />
              {/* <Image className="hpscroll-group-image" _hover={{ boxShadow: " 0px 0px 10px 2px rgba(0,0,0,0.2)" }} transition={"all 0.2s ease"} src={require("../../assets/ManufacturerLogos/Motherson.png")} onClick={() => props.onClickCategory({ value: "Motherson", name: "brand", label: "Motherson" })} /> */}
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/Bosch.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'Bosch',
                    name: 'brand',
                    label: 'Bosch',
                  })
                }
              />
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/Hiq.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'Hi-Q',
                    name: 'brand',
                    label: 'Hi-Q',
                  })
                }
              />
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/ICER.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'ICER',
                    name: 'brand',
                    label: 'ICER',
                  })
                }
              />
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/Ktek.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'KTEK',
                    name: 'brand',
                    label: 'KTEK',
                  })
                }
              />
              {/* <Image className="hpscroll-group-image" _hover={{ boxShadow: " 0px 0px 10px 2px rgba(0,0,0,0.2)" }} transition={"all 0.2s ease"} src={require("../../assets/ManufacturerLogos/Hella.png")} onClick={() => props.onClickCategory({ value: "Hella", name: "brand", label: "Hella" })} /> */}
              {/* <Image className="hpscroll-group-image" _hover={{ boxShadow: " 0px 0px 10px 2px rgba(0,0,0,0.2)" }} transition={"all 0.2s ease"} src={require("../../assets/ManufacturerLogos/Allied Nippon.png")} onClick={() => props.onClickCategory({ value: "Allied Nippon", name: "brand", label: "Allied Nippon" })} /> */}
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/Meyle.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'Meyle',
                    name: 'brand',
                    label: 'Meyle',
                  })
                }
              />
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/ATE.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'ATE',
                    name: 'brand',
                    label: 'ATE',
                  })
                }
              />
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/TVS Giriling.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'TVS Giriling',
                    name: 'brand',
                    label: 'TVS Giriling',
                  })
                }
              />
              <Image
                className="hpscroll-group-image"
                _hover={{ boxShadow: ' 0px 0px 10px 2px rgba(0,0,0,0.2)' }}
                transition={'all 0.2s ease'}
                src={require('../../assets/ManufacturerLogos/Supergrip.png')}
                onClick={() =>
                  props.onClickCategory({
                    value: 'Supergrip',
                    name: 'brand',
                    label: 'Supergrip',
                  })
                }
              />
            </Flex>

            <IoChevronForward
              fontSize="60px"
              color="#FBDE00"
              onClick={() => scroll(250)}
            />
          </Flex>
        </Flex>

        <Flex mt="5vw" position="relative" boxShadow="0px 0px 99px #00000026">
          <Image src={Ad2} loading="lazy" />
          <Button
            className="hpbtnAdDark"
            _hover={{ bgColor: '#fae10a', color: '#000' }}
            border="2px"
            borderColor="#000"
            position="absolute"
            bottom="15%"
            left="26%"
            bgColor="black"
            color="#fae10a"
            borderRadius="3xl"
            onClick={() =>
              props.onClickAds([
                {
                  value: 'Brake Rotor',
                  name: 'category',
                  label: 'Brake Rotor',
                },
              ])
            }
          >
            Shop Now
          </Button>
        </Flex>

        <Flex
          bgColor="white"
          mt="5vw"
          boxShadow="0px 0px 99px #00000026"
          width="100%"
          borderRadius="5px"
          padding="3vw 0 3vw 0vw"
          flexDirection="column"
        >
          <Text className="hpsubheads" mb="2vw" ml={'7vw'}>
            Best Selling Products
          </Text>
          <Divider marginLeft={'7vw'} color="#d8d8d8" />
          <Flex width="100%" alignItems="center" visibility="">
            <Box m={'1vw'}>
              <IoChevronBack
                visibility={hideLeftIcon ? 'hidden' : 'visible'}
                fontSize="60px"
                color="#FBDE00"
                onClick={() => scrollRight(320)}
              />
            </Box>

            <Grid
              gridAutoFlow="column"
              alignItems="flex-start"
              overflowX={'scroll'}
              overflowY="none"
              ref={ref}
            >
              {items}
            </Grid>

            <Box m={'1vw'}>
              <IoChevronForward
                fontSize="60px"
                color="#FBDE00"
                onClick={() => scrollLeft(320)}
              />
            </Box>
          </Flex>
        </Flex>

        <Flex mt="5vw" position="relative" boxShadow="0px 0px 99px #00000026">
          <Image src={Ad3} loading="lazy" />
          <Button
            className="hpbtnAdDark"
            _hover={{ bgColor: '#fae10a', color: '#000' }}
            border="2px"
            borderColor="#000"
            position="absolute"
            bottom="10%"
            left="48%"
            bgColor="black"
            color="#fae10a"
            borderRadius="3xl"
            onClick={() => props.onClickAds([{}])}
          >
            Shop Now
          </Button>
        </Flex>
        <Flex mt="5vw">
          <Flex position="relative" boxShadow="0px 0px 99px #00000026">
            <Image src={Ad4a} loading="lazy" />
            <Button
              className="hpbtnAdDark"
              _hover={{
                bgColor: 'transparent',
                color: '#000',
                border: '2px',
                borderColor: '#000',
              }}
              position="absolute"
              bottom="10%"
              left="40%"
              bgColor="#37b535"
              color="#000"
              borderRadius="3xl"
              onClick={() =>
                props.onClickAds([
                  { value: 'EBC', name: 'brand', label: 'EBC' },
                ])
              }
            >
              Shop Now
            </Button>
          </Flex>
          <Flex position="relative" ml="1vw" boxShadow="0px 0px 99px #00000026">
            <Image src={Ad4b} loading="lazy" />
            <Button
              className="hpbtnAdDark"
              _hover={{
                bgColor: 'transparent',
                color: '#000',
                border: '2px',
                borderColor: '#000',
              }}
              position="absolute"
              bottom="10%"
              left="40%"
              bgColor="#ded254"
              color="#000"
              borderRadius="3xl"
              onClick={() =>
                props.onClickAds([
                  { value: 'EBC', name: 'brand', label: 'EBC' },
                ])
              }
            >
              Shop Now
            </Button>
          </Flex>
        </Flex>

        <Flex mt="5vw" boxShadow="0px 0px 99px #00000026">
          <Image src={Quotebg} loading="lazy" />
        </Flex>

        <Flex mt="5vw" position="relative" boxShadow="0px 0px 99px #00000026">
          <Image src={Ad5} loading="lazy" />
          <Button
            className="hpbtnAdDark"
            _hover={{
              bgColor: '#fff',
              color: '#000',
              border: '2px',
              borderColor: '#000',
            }}
            position="absolute"
            bottom="10%"
            left="39%"
            bgColor="#C6070F"
            color="#fff"
            borderRadius="3xl"
            onClick={() =>
              props.onClickAds([
                { value: 'Brembo', name: 'brand', label: 'Brembo' },
              ])
            }
          >
            Shop Now
          </Button>
        </Flex>

        <Flex mt="5vw">
          <Flex position="relative" boxShadow="0px 0px 99px #00000026">
            <Image src={Ad6a} loading="lazy" />
            <Button
              className="hpbtnAdDark"
              _hover={{
                bgColor: 'transparent',
                color: '#000',
                border: '2px',
                borderColor: '#000',
              }}
              position="absolute"
              bottom="10%"
              left="21%"
              bgColor="#fae10a"
              color="black"
              borderRadius="3xl"
              onClick={() => props.onClickAds([{}])}
            >
              Shop Now
            </Button>
          </Flex>
          <Flex position="relative" ml="1vw" boxShadow="0px 0px 99px #00000026">
            <Image src={Ad6b} loading="lazy" />
            <Button
              className="hpbtnAdDark"
              _hover={{
                bgColor: 'transparent',
                color: '#000',
                border: '2px',
                borderColor: '#000',
              }}
              position="absolute"
              bottom="3%"
              right="28%"
              bgColor="#1a4a74"
              color="#fff"
              borderRadius="3xl"
              onClick={() =>
                props.onClickAds([
                  { value: 'Meyle', name: 'brand', label: 'Meyle' },
                  {
                    value: '63087a8278de19ec4482d487',
                    name: 'make',
                    label: 'Volkswagen',
                  },
                ])
              }
            >
              Shop Now
            </Button>
          </Flex>
        </Flex>

        <Flex
          mt="5vw"
          bgColor="white"
          width="100%"
          boxShadow="0px 0px 99px #00000026"
          borderRadius="5px"
        >
          <Grid
            templateColumns="repeat(5, 1fr)"
            justifyContent="space-evenly"
            padding="1vw"
            justifyItems="center"
            alignItems="baseline"
          >
            <GridItem className="highlightSec " padding="1vw" margin="1vw">
              <Image className="highlightsImg" src={high1} />
              <Text className="highlightsfont ">Find Top Brands </Text>
            </GridItem>
            <GridItem className="highlightSec" padding="1vw" margin="1vw">
              <Image className=" highlightsImg" src={high2} />
              <Text className="highlightsfont">
                Genuine Products Guaranteed{' '}
              </Text>
            </GridItem>
            <GridItem className="highlightSec" padding="1vw" margin="1vw">
              <Image className=" highlightsImg" src={high3} />
              <Text className="highlightsfont">Easy Compatibility Check </Text>
            </GridItem>
            <GridItem className="highlightSec" padding="1vw" margin="1vw">
              <Image className=" highlightsImg" src={high4} />
              <Text className="highlightsfont">Fast & Free Delivery </Text>
            </GridItem>
            <GridItem className="highlightSec" padding="1vw" margin="1vw">
              <Image className="highlightsImg" src={high5} />
              <Text className="highlightsfont">Discount Deals </Text>
            </GridItem>
          </Grid>
        </Flex>

        <Flex mt="5vw" position="relative" boxShadow="0px 0px 99px #00000026">
          <Image src={Ad7} loading="lazy" />
          <Button
            className="hpbtnAdDark"
            _hover={{
              bgColor: 'transparent',
              color: '#fff',
              border: '2px',
              borderColor: '#fff',
            }}
            position="absolute"
            bottom="9%"
            left="87%"
            bgColor="#fff"
            color="#000"
            borderRadius="3xl"
            onClick={() =>
              props.onClickAds([
                { value: 'Bosch', name: 'brand', label: 'Bosch' },
                {
                  value: 'Brake Fluid',
                  name: 'category',
                  label: 'Brake Fluid',
                },
              ])
            }
          >
            Shop Now
          </Button>
        </Flex>

        <Flex mt="5vw">
          <Flex position="relative" boxShadow="0px 0px 99px #00000026">
            <Image src={Ad8a} loading="lazy" />
            <Button
              className="hpbtnAdDark"
              _hover={{
                bgColor: 'transparent',
                color: '#fff',
                border: '2px',
                borderColor: '#fff',
              }}
              position="absolute"
              bottom="25%"
              left="4%"
              bgColor="#000"
              color="#fff"
              borderRadius="3xl"
              onClick={() =>
                props.onClickAds([
                  {
                    value: '63107ec6b96afe78239a1662',
                    name: 'make',
                    label: 'Maruti Suzuki',
                  },
                  {
                    value: '63087a8278de19ec4482d498',
                    name: 'model',
                    label: 'Alto',
                  },
                ])
              }
            >
              Shop Now
            </Button>
          </Flex>
          <Flex
            position="relative"
            ml="1vw"
            boxShadow="0px 0px 99px #00000026"
            onClick={() =>
              props.onClickAds([
                { value: 'ICER', name: 'brand', label: 'ICER' },
              ])
            }
          >
            <Image src={Ad8b} loading="lazy" />
            <Box className="hpaAdLottie8b" position="absolute">
              <lottie-player
                src={'https://api.brakeup.in/api/v1/json/Brakeup_Meyle_Ad.json'}
                background="transparent"
                speed="1"
                loop
                autoplay
              ></lottie-player>
            </Box>
          </Flex>
        </Flex>

        <AdsWeb
          adStyle={{ marginTop: '5vw', boxShadow: '0px 0px 99px #00000026' }}
        />

        {/* <>
          <Box
            className="rotate-container"
            onClick={
              isEnquiryPopoverOpen ? closeEnquiryPopover : openEnquiryPopover
            }
            position="fixed"
            zIndex={6}
            bottom={27}
            right="16px"
          >
            <Image
              src={singleroundlogo}
              alt="Round PNG"
              className="rotating-image"
            />
          </Box>
          {isMessageDisplayed && (
            <Box
              className={`md:mb-2 flex items-center justify-center font-bold message slide-up`}
              style={{
                position: "fixed",
                zIndex: 5,
                bottom: isEnquiryPopoverOpen ? -100 : 77,
                right: 27,
              }}
            >
              <div className="bg-white font-manrope border border-gray-300 shadow-md rounded-lg text-black font-bold text-base  px-4 py-2">
                {" "}
                Hi! Need any assistance?
              </div>
              <div className="">
                <div className="group">
                  <button
                    onClick={closeMessage}
                    className="opacity-0 group-hover:opacity-100"
                    style={{
                      position: "absolute",
                      bottom: "50px",
                      right: "0px",
                      cursor: "pointer",
                    }}
                  >
                    <Image
                      src={closemark}
                      alt=""
                      color="greyB5"
                      layout="responsive"
                      className="w-25 md:w-30 h-25 opacity-0 group-hover:opacity-100"
                    />
                  </button>
                </div>
              </div>
            </Box>
          )}
          <EnquiryPopover
            isOpen={isEnquiryPopoverOpen}
            onClose={closeEnquiryPopover}
          />
        </> */}
      </Flex>
    </Box>
  );
};
