import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  VStack,
  Image,
  Heading,
  Divider,
} from '@chakra-ui/react';
import { API_URL } from '../../../services/api';
import { FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { setAccess } from '../../../stateManagement/user/userSlice';

import { useDispatch } from 'react-redux';

import moment from 'moment';

import rolledout from '../../../assets/sarath_IMG/bg_item_rolled_out_soon.png';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  CloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  Deactivate1AreYou,
  Deactivate2OTP,
  Deactivate3Final,
  PasswordChanged,
  PasswordChangeError,
  ProfileModals,
  ToChangePassword,
} from './ProfileMobileModals';

import { get, post } from '../../../services/api';
import { setUserName } from '../../../stateManagement/user/userSlice';

export const ProfileMob = (props) => {
  const dispatch = useDispatch();

  // modal content swapping

  const [modalContent, setModalContent] = useState(null);
  let history = useHistory();
  const changeModalContent = (value, type) => {
    setModalContent(type);
  };

  //Edit

  const [editDisplay, setEditDisplay] = useState(false);
  const [profile, setProfile] = useState({});
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({ mode: 'onChange' });

  //save data for editing

  useEffect(() => {
    setTimeout(() => {
      if (Object.keys(profile).length > 0) {
        setValue('name', profile?.name);
        setValue('email', profile?.email);
        setValue('dob', profile?.dob);
        setValue('mobile', profile?.mobile);
      }
    }, 1000);
  }, [editDisplay]);

  const onSubmit = (data) => {
    post('/api/v2/user/profileupdate/', data)
      .then((res) => {
        // console.log(data, "dataaaaaa");
        setEditDisplay(false);
        getProfile();
        dispatch(setUserName(data.name));
      })
      .catch((err) => {});
  };

  //modal

  const { isOpen, onOpen, onClose } = useDisclosure();

  //Alert Dialog PopUp
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();

  const cancelRef = React.useRef();

  //toast
  const Swal = require('sweetalert2');

  const Toast2 = (title, theme, color, iconColor) => {
    Swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      autofocus: false,
      toast: true,
      animation: true,
      timer: 5000,
      // timerProgressBar: true,
      iconColor: iconColor,
      icon: theme,
      title: title,
      color: color,

      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
  };

  const getProfile = () => {
    get('/api/v2/user/profileupdate/')
      .then((res) => {
        setProfile(res);
      })
      .catch((err) => {
        setProfile({});
      });
  };
  useEffect(() => {
    getProfile();
  }, []);

  //OTP
  const [OTP, setOTP] = useState('');

  const changePassword = (d) => {
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    d.user_id = token?.data?.credAuthResult?.id;
    post('/api/v2/password_change/', d)
      .then((res) => {
        getProfile();
        setModalContent(2);
      })
      .catch((err) => {
        setModalContent(3);
      });
  };

  return (
    <>
      <Flex
        bgColor="white"
        flexDirection="column"
        alignItems="center"
        minH="77vh"
        width={'100%'}
      >
        <Text className="mob_address_head_font" mt="30px">
          Profile
        </Text>

        <Flex
          className="mob_main_box_profiles"
          marginTop="50px"
          justifyContent="space-between"
          boxShadow="0px 0px 3px #00000033"
        >
          <VStack alignItems="flex-start" width="100%">
            <HStack justifyContent="space-between" width="100%">
              <Text className="mob_heading_profile manrope-bold-shark">
                {' '}
                Personal Information
              </Text>
              <Box>
                {editDisplay ? (
                  <Button
                    _focus={{ bgColor: 'none' }}
                    onClick={handleSubmit((d) => onSubmit(d))}
                    className="mob_button_edit_saves"
                    borderRadius="5px"
                    bgColor="var(--royal-blue)"
                    color="white"
                    boxShadow="0px 2px 6px #00000029"
                  >
                    {' '}
                    Save
                  </Button>
                ) : (
                  <Button
                    _focus={{ bgColor: 'none' }}
                    onClick={() => {
                      setEditDisplay(true);
                    }}
                    className="mob_button_edit_saves"
                    borderRadius="5px"
                    bgColor="var(--white)"
                    boxShadow="0px 2px 6px #00000029"
                  >
                    {' '}
                    <FiEdit style={{ marginRight: '10px' }} /> Edit
                  </Button>
                )}
              </Box>
            </HStack>
            <Flex className="mob_databox_profile" mt="100px" width="100%">
              <Flex
                flexDirection="column"
                alignItems="flex-start"
                marginRight="20px"
                className="mob_ inputoutput nunitosans-normal-mountain-mist"
              >
                <Text className="mob_inputoutput_text">Name</Text>
                <Text className="mob_inputoutput_text">Email</Text>
                <Text className="mob_inputoutput_text">Dob</Text>
                <Text className="mob_inputoutput_text">Mobile</Text>
              </Flex>

              {editDisplay ? (
                <form style={{ width: '100%' }}>
                  <Flex
                    flexDirection="column"
                    alignItems="flex-start"
                    width="100%"
                    className="mob_ inputoutput nunitosans-normal-shark"
                  >
                    <input
                      name="name"
                      ref={register({ required: true })}
                      className="mob_inputbox_profile"
                    />
                    <input
                      name="email"
                      type="email"
                      ref={register({ required: false })}
                      className="mob_inputbox_profile"
                    />
                    <input
                      name="dob"
                      type="date"
                      ref={register({ required: false })}
                      className="mob_inputbox_profile"
                    />
                    <input
                      name="mobile"
                      ref={register({ required: true })}
                      className="mob_inputbox_profile"
                    />
                  </Flex>
                </form>
              ) : (
                <Flex
                  flexDirection="column"
                  alignItems="flex-start"
                  width="100%"
                  className="mob_ inputoutput nunitosans-normal-shark"
                >
                  <Text className="mob_inputoutput_text">{profile?.name}</Text>
                  <Text className="mob_inputoutput_text">{profile?.email}</Text>
                  <Text className="mob_inputoutput_text">
                    {moment(profile?.dob).format('DD MMM YYYY')}
                  </Text>
                  <Text className="mob_inputoutput_text">
                    {profile?.mobile}
                  </Text>
                </Flex>
              )}
            </Flex>
          </VStack>
        </Flex>

        <Flex justifyContent="space-between" mt="120px" width="80%">
          <Button
            variant="ghost"
            colorScheme="blackAlpha"
            borderRadius="5px"
            className="nunitosans-normal-mountain-mist mob_btn3"
            onClick={() => {
              onOpen();
              setModalContent(4);
            }}
          >
            Delete Account
          </Button>
          <Flex flexDirection="column" alignItems="flex-end">
            <Button
              borderRadius="5px"
              bgColor="var(--white)"
              boxShadow="0px 2px 6px #00000029"
              className="mob_btn3 nunitosans-normal-mountain-mist"
              onClick={() => {
                onOpen();
                setModalContent(1);
              }}
            >
              Change Password{' '}
            </Button>
            <Button
              mt="20px"
              borderRadius="5px"
              bgColor="var(--white)"
              border="1px solid #707070"
              boxShadow="0px 2px 6px #00000029"
              className="mob_btn3 nunitosans-normal-mountain-mist"
              onClick={onOpenDelete}
            >
              Sign out
            </Button>
          </Flex>
        </Flex>

        <Box
          bgColor="var(--white)"
          flexDirection="column"
          mt="50px"
          padding="30px"
          width="100%"
        >
          <Text className="mob_review_heading_profile manrope-bold-shark">
            Reviews
          </Text>
          <Flex
            bgImage={rolledout}
            height="350px"
            bgSize="contain"
            bgRepeat="no-repeat"
            justifyContent="center"
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              className="mob_review_box"
            >
              <Box width="160px" height="160px">
                <lottie-player
                  src={'https://api.brakeup.in/api/v1/json/Gear_Spanner.json'}
                  background="transparent"
                  speed="1"
                  loop
                  autoplay
                ></lottie-player>
              </Box>
              <Text className="mob_feature_rolled_out">
                THIS FEATURE WILL BE ROLLED OUT SOON
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Flex>

      {/* all modal */}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="sm"
        isCentered
        autoFocus={false}
      >
        <ModalOverlay />
        {(() => {
          switch (modalContent) {
            case 1:
              return (
                <ToChangePassword
                  onClose={onClose}
                  cancelRef={cancelRef}
                  setModalContent={changeModalContent}
                  changePassword={changePassword}
                />
              );
            case 2:
              return (
                <PasswordChanged onClose={onClose} cancelRef={cancelRef} />
              );
            case 3:
              return (
                <PasswordChangeError onClose={onClose} cancelRef={cancelRef} />
              );
            case 4:
              return (
                <Deactivate1AreYou
                  onClose={onClose}
                  cancelRef={cancelRef}
                  setModalContent={setModalContent}
                />
              );
            case 5:
              return (
                <Deactivate2OTP
                  OTP={OTP}
                  setOTP={setOTP}
                  setModalContent={setModalContent}
                />
              );
            case 6:
              return (
                <Deactivate3Final onClose={onClose} cancelRef={cancelRef} />
              );
          }
        })()}
      </Modal>

      {/* delete popup */}

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseDelete}
        isOpen={isOpenDelete}
        isCentered
        size={'md'}
        borderRadius="1px"
        autoFocus={false}
      >
        <AlertDialogOverlay />

        <AlertDialogContent bgColor="white">
          <AlertDialogCloseButton size="md" color="var(--mountain-mist)" />

          <AlertDialogBody>
            <AlertDialogHeader
              fontSize="1.1rem"
              className="mob_nunitosans-normal-shark"
              mt="20px"
            >
              Are you sure you want to Sign out ?
            </AlertDialogHeader>
          </AlertDialogBody>
          <AlertDialogFooter mb="2rem">
            <Button
              className="mob_ alertbuttonfont nunitosans-normal-shark"
              onClick={() => {
                onCloseDelete();
                // if (!toast.isActive(id)) {
                //     toast({
                //         id,
                //         duration: 5000,
                //         position: "bottom",
                //         isClosable: true,
                //         containerStyle: {
                //             marginBottom: '15%',
                //         },

                //         render: () => (

                //             <Box color="blue" border="1px solid" className='toastbox' bgColor='white'>
                //                 <Flex justifyContent='space-between' alignItems='center'>
                //                     <FaInfoCircle className='toasticon' />
                //                     <Text className="font_NunitoA toastfont">Signed Out Successfully !</Text>
                //                     <IoClose className='toastclose' onClick={closeToast} />
                //                 </Flex>
                //             </Box>)
                //     })
                // };
                Toast2('Signed Out Successfully !', 'success', '#089520');
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('pincode');
                window.localStorage.removeItem('appliedpromocode');
                dispatch(setAccess(false));
                history.push('/');
                window.location.reload();
              }}
              bgColor="transparent"
              border="1px solid"
              borderRadius="5px"
              size={'sm'}
              boxShadow="0px 3px 6px #00000029"
            >
              SIGN OUT
            </Button>

            <Button
              className="mob_nunitosans-normal-shark"
              ref={cancelRef}
              ml={3}
              borderRadius="5px"
              size={'sm'}
              boxShadow={'0px 3px 6px #330d2f29'}
              bgColor={'var(--white-rock-2)'}
              onClick={() => {
                onCloseDelete();
              }}
            >
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
