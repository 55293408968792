import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Select,
  Text,
  Image,
  VStack,
  HStack,
  Divider,
} from '@chakra-ui/react';
import { API_URL } from '../../../services/api';
import { get, post } from '../../../services/api';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

export const OrderHistoryMob = (props) => {
  let history = useHistory();
  const [order, setOrder] = useState([]);
  const getOrders = () => {
    get('/api/v2/order/orderprodlist/')
      .then((res) => {
        setOrder(res);
      })
      .catch((err) => {
        setOrder([]);
      });
  };
  useEffect(() => {
    getOrders();
  }, []);
  const moveToReplacement = (item) => {
    history.push(
      `/replacemob/${item.order_id}/${item.prod_id}/${item.vendor_id}/${item.user_id['$oid']}`
    );
  };
  const downloadInvoice = (invoiceurl) => {
    window.open(invoiceurl, 'invoice');
  };

  //toast
  const Swal = require('sweetalert2');

  const Toast2 = (title, theme, color, iconColor) => {
    Swal.fire({
      showConfirmButton: false,
      showCloseButton: true,
      autofocus: false,
      toast: true,
      animation: true,
      timer: 5000,
      // timerProgressBar: true,
      iconColor: iconColor,
      icon: theme,
      title: title,
      color: color,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
  };

  const cancelOrder = (ord) => {
    // console.log(ord,"ordddddddds");
    post('/api/v2/seller/ordercancel_buyer/', ord)
      .then((res) => {
        Toast2('Order cancelled Successfully !', 'success', '#089520');
        getOrders();
      })
      .catch((err) => {
        Toast2(err?.response?.data.message, 'error', '#f22020');
        getOrders();
      });
  };

  return (
    <Flex
      margin="15px"
      bgColor="white"
      flexDirection="column"
      alignItems="center"
      minH="77vh"
      width={'100%'}
    >
      <Text className="mob_address_head_font" mt="30px">
        Order
      </Text>

      {order.length == 0 ? (
        <Flex
          alignSelf="center"
          flexDirection="column"
          alignItems="center"
          className="flexbox_Empty_State"
        >
          <Box className="savederrorjson">
            <lottie-player
              src={
                'https://api.brakeup.in/api/v1/json/Brakeup_Empty_State.json'
              }
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </Box>

          <Flex
            className="textbox_Empty_State"
            flexDir="column"
            alignItems="center"
          >
            <Text
              className="bold_text_Empty_State nunitosans-bold-shark "
              whiteSpace="nowrap"
              textAlign="center"
            >
              {' '}
              No orders yet
            </Text>
            <Text
              className="text2_Empty_State nunitosans-normal-melanzane"
              whiteSpace="nowrap"
              textAlign="center"
            >
              Go and find products you like{' '}
            </Text>
            <button
              className="btn_empty_state nunitosans-bold-shark"
              onClick={() => history.push('/')}
            >
              Start Shopping
            </button>
          </Flex>
        </Flex>
      ) : (
        <>
          {order &&
            order.map((items) => {
              return (
                <>
                  <Box margin="15px" borderRadius="5px" width="95%">
                    <Flex
                      bgColor="var(--cultured-pearl)"
                      className="mob_order_header"
                    >
                      <Flex flexDirection="column" width="30%">
                        <Text className="mob_ order_headings manrope-normal-shark">
                          ORDER PLACED
                        </Text>
                        <Text className="mob_order_headings manrope-normal-shark">
                          TOTAL AMOUNT
                        </Text>
                        <Text className="mob_order_headings manrope-normal-shark">
                          ORDER #
                        </Text>
                      </Flex>
                      <Flex
                        flexDirection="column"
                        justifyContent="space-between"
                        width="70%"
                      >
                        <Text
                          ml="10px"
                          className="mob_ order_head_sub nunitosans-bold-shark"
                        >
                          {moment(items?.created_at['$date']).format(
                            'DD MMM YYYY'
                          )}{' '}
                          <span className="mob_ order_head_sub_time nunitosans-semi-bold-shark">
                            {moment(items?.created_at['$date']).format(
                              ' hh:mm a'
                            )}
                          </span>
                        </Text>

                        <Text
                          ml="10px"
                          className="mob_order_head_sub nunitosans-bold-shark"
                        >
                          ₹ {items.order_total}
                        </Text>

                        <Flex justifyContent="space-between">
                          <Flex alignItems="center">
                            <Text
                              ml="10px"
                              className="mob_order_head_sub nunitosans-bold-shark"
                            >
                              {items?.buyer_details?.order_Id}
                            </Text>
                          </Flex>
                          <Text
                            textAlign="end"
                            textTransform="capitalize"
                            className="mob_order_head_sub nunitosans-bold-shark"
                          >
                            {items?.buyer_details?.customer_Name}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    {items.order_prod_details &&
                      items.order_prod_details.map((pro) => {
                        // console.log(pro, "propropropro");
                        return (
                          <Flex
                            boxShadow="0px 3px 6px #00000029"
                            flexDirection="column"
                            width="100%"
                          >
                            <Flex
                              className="mob_orderdata"
                              flexDirection="column"
                            >
                              <Flex>
                                <Flex
                                  className="mob_order_image_qua"
                                  flexDirection="column"
                                  justifyContent="space-between"
                                >
                                  <Image
                                    onClick={() =>
                                      props.ToProductDetailPage(pro)
                                    }
                                    className="mob_order_image"
                                    objectFit="contain"
                                    src={pro?.image[0]}
                                  />
                                  <Text className="mob_ order_quantity_dis nunitosans-normal-melanzane">
                                    Quantity : {pro.quantity}{' '}
                                  </Text>
                                </Flex>
                                <Flex flexDirection="column" width="100%">
                                  <Flex
                                    flexDirection="column"
                                    alignItems="flex-start"
                                    marginLeft="2vw"
                                  >
                                    <Text
                                      onClick={() =>
                                        props.ToProductDetailPage(pro)
                                      }
                                      className="mobredirect"
                                    >
                                      <Text className="mob_ order_brand nunitosans-black-venus">
                                        {pro.brand}
                                      </Text>
                                      <Text className="mob_ order_product nunitosans-normal-melanzane">
                                        {pro.prod_name}
                                      </Text>

                                      {pro?.vehicle_details &&
                                        pro?.vehicle_details?.make != '' && (
                                          <Flex
                                            alignItems="center"
                                            whiteSpace="nowrap"
                                            className="mob_detailbox"
                                          >
                                            <img
                                              className="mob_logo-logo-png"
                                              src={pro?.vehicle_details?.logo}
                                            />
                                            <Text
                                              ml={2}
                                              marginTop={0}
                                              className="mob_  order_product_data nunitosans-normal-melanzane"
                                            >
                                              {pro?.vehicle_details?.model}{' '}
                                              {pro?.vehicle_details?.version}{' '}
                                              {pro?.vehicle_details?.year}{' '}
                                              {pro?.vehicle_details?.fueltype}
                                            </Text>
                                          </Flex>
                                        )}
                                    </Text>
                                    <HStack
                                      marginTop="3px"
                                      className="mob_order_quantity_dis nunitosans-light-black-pearl"
                                    >
                                      <Text>
                                        Dispatch from : {pro.vendor_city}{' '}
                                      </Text>
                                    </HStack>
                                    <Flex
                                      justifyContent="space-between"
                                      width="100%"
                                      marginTop="10px"
                                    >
                                      <Box>
                                        {pro.order_status == 'DELIVERED' ? (
                                          <Text
                                            whiteSpace="nowrap"
                                            className="mob_order_dotted_text nunitosans-normal-mountain-mist"
                                            borderStyle="dashed"
                                            borderColor="#959595"
                                            border="1px dashed"
                                            color="#959595"
                                          >
                                            DELIVERY COMPLETED
                                          </Text>
                                        ) : (
                                          ''
                                        )}
                                      </Box>

                                      {pro?.replacement == true ? (
                                        <Button
                                          className="mob_order_button_big"
                                          mt="0px"
                                          bgColor="var(--baby-powder)"
                                          borderRadius="5px"
                                          boxShadow="0px 2px 6px #00000029"
                                          onClick={() => moveToReplacement(pro)}
                                        >
                                          {pro.replacement_status
                                            ? pro.replacement_status
                                            : 'Replacement'}
                                        </Button>
                                      ) : (
                                        <Button
                                          className="mob_order_button_big"
                                          disabled
                                          mt="0px"
                                          bgColor="#00000029"
                                          borderRadius="5px"
                                          boxShadow="0px 2px 6px #00000029"
                                        >
                                          {pro.replacement_status
                                            ? pro.replacement_status
                                            : 'Replacement'}
                                        </Button>
                                      )}
                                    </Flex>
                                  </Flex>
                                </Flex>
                              </Flex>

                              <Flex marginTop={'20px'} alignItems="center">
                                <Box>
                                  <Button
                                    display="none"
                                    className="mob_order_button_big"
                                    bgColor="var(--baby-powder)"
                                    borderRadius="5px"
                                    boxShadow="0px 2px 6px #00000029"
                                  >
                                    {' '}
                                    Replacement{' '}
                                  </Button>
                                </Box>
                                <Button
                                  className="mob_order_button_big"
                                  disabled
                                  bgColor="#00000029"
                                  borderRadius="5px"
                                  boxShadow="0px 2px 6px #00000029"
                                >
                                  {' '}
                                  Write Review
                                </Button>

                                <Flex className="mob_order_bot_btn">
                                  {/* {pro.isInvoiced == false ? <Button className="mob_order_button_sml"
                            bgColor="var(--baby-powder)" borderRadius='5px'
                            boxShadow='0px 2px 6px #00000029'
                            mr='10px'   > Cancel</Button> : ""} */}
                                  {pro.isInvoiced && (
                                    <Button
                                      className="mob_order_button_med"
                                      bgColor="var(--baby-powder)"
                                      borderRadius="5px"
                                      boxShadow="0px 2px 6px #00000029"
                                      mr="10px"
                                      onClick={() => {
                                        downloadInvoice(pro?.invoice);
                                      }}
                                    >
                                      Invoice
                                    </Button>
                                  )}
                                  {!pro.isInvoiced && !pro.isCancelled && (
                                    <Button
                                      className="mob_order_button_med"
                                      bgColor="var(--baby-powder)"
                                      borderRadius="5px"
                                      boxShadow="0px 2px 6px #00000029"
                                      mr="10px"
                                      onClick={() => {
                                        cancelOrder({
                                          order_id: pro.order_id,
                                          prod_id: pro.prod_id,
                                        });
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  )}
                                </Flex>
                                <Flex flexDir="column" position="relative">
                                  <Text className=" order_quantity_dis nunitosans-normal-mountain-mist">
                                    Order status :
                                  </Text>
                                  <Text className=" order_headings nunitosans-normal-melanzane">
                                    {pro.buyer_status}
                                  </Text>
                                </Flex>
                              </Flex>
                              {pro?.track_id && (
                                <Button
                                  mr="5vw"
                                  bgColor="#414bdb"
                                  className="mob_order_button_track"
                                  padding={1}
                                  color="white"
                                  borderRadius="3px"
                                  onClick={() => {
                                    downloadInvoice(pro.track_id);
                                  }}
                                  alignSelf="flex-end"
                                >
                                  TRACK{' '}
                                </Button>
                              )}
                            </Flex>

                            <Box className="mob_order_divider">
                              <Divider color="#C9C9C9" />
                            </Box>
                          </Flex>
                        );
                      })}
                  </Box>
                </>
              );
            })}
        </>
      )}
    </Flex>
  );
};
