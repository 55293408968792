import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  VStack,
  Image,
  Heading,
  Divider,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { API_URL, post } from '../../services/api';

import { useForm, Controller, useFieldArray } from 'react-hook-form';

//    request messsage for replacement

export const RequestMessage = (props) => {
  console.log(props, 'request message');

  return (
    <ModalContent
      bgColor={props.modalmob ? 'var(--cultured-pearl)' : 'var(--white-rock-2)'}
    >
      <ModalCloseButton color="var(--mountain-mist)" />
      <ModalBody display="flex" flexDirection="column" alignContent="center">
        <Flex className="RequestMessage_lottie" alignSelf="center">
          <lottie-player
            src={'https://api.brakeup.in/api/v1/json/Replacement_request.json'}
            background="transparent"
            speed="1"
            autoplay
            loop
          ></lottie-player>
        </Flex>
        <Box className="RMtextbox">
          <Text
            whiteSpace="nowrap"
            textAlign="center"
            className="m1font nunitosans-normal-melanzane"
          >
            {' '}
            Request recieved for replacement of product
          </Text>
          <Text
            whiteSpace={props.modalmob ? 'wrap' : 'nowrap'}
            textAlign="center"
            className="m1font nunitosans-normal-melanzane"
          >
            {' '}
            Our team will swiftly analyse your request and revert within 30
            minutes
          </Text>
        </Box>

        <ModalFooter justifyContent="center">
          <Button
            className=" m1closeBtn nunitosans-normal-melanzane"
            borderRadius="5px"
            border="1px solid"
            boxShadow={'0px 3px 6px #330d2f29'}
            bgColor="transparent"
            alignSelf="center"
            onClick={() => {
              props.onClose();
            }}
            autoFocus
          >
            CLOSE
          </Button>
        </ModalFooter>
      </ModalBody>
    </ModalContent>
  );
};

// add bank account details
export const BankAccountInput = (props) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm({ mode: 'onSubmit', defaultValues: {} });
  const onSubmit = (d) => {
    post('/api/v2/buyer/bank_account/', d)
      .then((res) => {
        props.getAccountDetails(d);
      })
      .catch((err) => {});
  };
  return (
    <ModalContent
      bgColor={props.modalmob ? 'var(--cultured-pearl)' : 'var(--white-rock-2)'}
    >
      <ModalCloseButton size="lg" color="var(--mountain-mist)" />
      <ModalBody alignContent="center" className="RplaccntModal">
        <form>
          <Text className=" pass_heading manrope-bold-shark  ">
            {' '}
            BANK ACCOUNT DETAILS
          </Text>
          <input
            autoFocus={true}
            className="inputbox_addAccDetails"
            placeholder="Enter Name"
            name="name"
            ref={register({
              required: true,
            })}
          ></input>

          <input
            className="inputbox_addAccDetails"
            placeholder="Account Number"
            name="account_no"
            ref={register({
              required: true,
            })}
          ></input>

          <input
            className="inputbox_addAccDetails"
            placeholder="IFSC"
            name="bank_IFSC_no"
            ref={register({
              required: true,
            })}
          ></input>

          <input
            className="inputbox_addAccDetails"
            placeholder="Bank Name"
            name="bank_name"
            ref={register({
              required: true,
            })}
          ></input>

          <input
            className="inputbox_addAccDetails"
            placeholder="Branch Name"
            name="branch_name"
            ref={register({
              required: true,
            })}
          ></input>

          <Flex mt="20px" justifyContent="flex-end">
            <Button
              className="accnt_btn nunitosans-normal-white"
              ref={props.cancelRef}
              color={props.modalmob ? 'black' : 'white'}
              width={props.modalmob ? '75px' : '150px'}
              borderRadius="5px"
              boxShadow={'0px 3px 6px #330d2f29'}
              bgColor={
                props.modalmob ? 'var(--white-rock)' : 'var(--royal-blue)'
              }
              onClick={handleSubmit((d) => onSubmit(d))}
            >
              Next
            </Button>
          </Flex>
        </form>
      </ModalBody>
    </ModalContent>
  );
};

// show bank account details
export const BankAccountDetails = (props) => {
  return (
    <ModalContent
      bgColor={props.modalmob ? 'var(--cultured-pearl)' : 'var(--white-rock-2)'}
    >
      <ModalCloseButton size="lg" color="var(--mountain-mist)" />
      <ModalBody alignContent="center" padding="70px 80px">
        <Text textAlign="center" className=" pass_heading manrope-bold-shark ">
          {' '}
          BANK ACCOUNT DETAILS
        </Text>

        <Flex
          justifyContent="center"
          mt="20px"
          className="bankAcctDetailFont nunitosans-normal-melanzane"
        >
          <Flex flexDirection="column" alignItems="flex-end">
            <Text className="bankAccntFont"> Name :</Text>
            <Text className="bankAccntFont"> Account Number :</Text>
            <Text className="bankAccntFont"> IFSC :</Text>
            <Text className="bankAccntFont"> Bank Name :</Text>
            <Text className="bankAccntFont"> Branch Name :</Text>
          </Flex>
          <Flex
            flexDirection="column"
            marginLeft="10px"
            alignItems="flex-start"
          >
            <Text className="bankAccntFont"> {props?.accDtls?.name}</Text>
            <Text className="bankAccntFont"> {props?.accDtls?.account_no}</Text>
            <Text className="bankAccntFont">
              {' '}
              {props?.accDtls?.bank_IFSC_no}
            </Text>
            <Text className="bankAccntFont"> {props?.accDtls?.bank_name}</Text>
            <Text className="bankAccntFont">
              {' '}
              {props?.accDtls?.branch_name}
            </Text>
          </Flex>
        </Flex>

        <Flex mt="30px" justifyContent="center">
          <Button
            size={'sm'}
            className=" accnt_btn nunitosans-normal-white"
            ref={props.cancelRef}
            width={props.modalmob ? '100px' : '150px'}
            color={props.modalmob ? 'black' : 'white'}
            borderRadius="5px"
            boxShadow={'0px 3px 6px #330d2f29'}
            bgColor={props.modalmob ? 'var(--white-rock)' : 'var(--royal-blue)'}
            onClick={() => {
              props.setModalContent(4);
            }}
          >
            CONFIRM
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};

//    Refund initiated  Message

export const RefundedMessage = (props) => {
  return (
    <ModalContent
      bgColor={props.modalmob ? 'var(--cultured-pearl)' : 'var(--white-rock-2)'}
    >
      <ModalCloseButton color="var(--mountain-mist)" />
      <ModalBody display="flex" flexDirection="column" alignContent="center">
        {props.modalmob ? (
          ''
        ) : (
          <Flex className="RefundedMessage_lottie" alignSelf="center">
            <lottie-player
              src={'https://api.brakeup.in/api/v1/json/Refund_final.json'}
              background="transparent"
              speed="1"
              autoplay
              loop
            ></lottie-player>
          </Flex>
        )}
        <Box className="RefundedMessageTextbox">
          <Text
            textAlign="center"
            className="m1font nunitosans-normal-melanzane"
          >
            Your refund of an amount ₹ {props.discountAmount} (rupees) will be
            initiated
          </Text>
        </Box>
      </ModalBody>

      <ModalFooter justifyContent="center" mb={props.modalmob ? '' : '50px'}>
        <Button
          className="accnt_btn nunitosans-normal-white"
          ref={props.cancelRef}
          borderRadius="5px"
          boxShadow={'0px 3px 6px #330d2f29'}
          color={props.modalmob ? 'black' : 'white'}
          bgColor={props.modalmob ? 'var(--white-rock)' : 'var(--royal-blue)'}
          onClick={() => {
            props.onClose();
          }}
        >
          CLOSE
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
