// const accessToken =
//   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjYxZmJhMjhjNjJlMjQ5ZWM4YWQxZGY1NCIsIm5hbWUiOm51bGwsImVtYWlsIjpudWxsLCJtb2JpbGUiOiI4MDg5NDIxNjMwIiwiaXNWZXJpZmllZCI6dHJ1ZSwiaXNBY3RpdmUiOnRydWUsInVzZXJfdHlwZSI6W10sInR5cGUiOiJBY2Nlc3MifQ.FJBrEkHcL7n9IXBlO277COoWTDrbsMQaakIQFrrAX0M"
// export default accessToken


const newToken = JSON.parse(localStorage.getItem("token") || "{}");

const accessToken = "Bearer " + newToken?.data?.credAuthResult?.access_token;

export default accessToken
